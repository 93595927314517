import React from "react";
import { Link } from "react-router-dom";

export default function WithdrawQr(){
    return(
        <>
        <div className="container-fluid">
            <div className="container my-4">
            <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                    <p className="box-title text-white">Withdraw</p>
                                    <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                <i className="fa-solid fa-xmark"></i>
                            </Link>
                                </header>
                <div className="w-100 box-block position-relative">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">Withdraw Currency</label>
                            <div className="position-relative">
                                <select className="form-select">
                                    <option>MATIC</option>
                                    <option>MATIC</option>
                                </select>
                                <p className="m-0 balance-withdraw-span bg-light border rounded">Balance <span>0</span></p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Choose Network</label>
                            <div className="position-relative">
                                <select className="form-select">
                                    <option>Polygon</option>
                                    <option>Polygon</option>
                                </select>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Withdrawal Address</label>
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Fill in carefully according to the specific currency" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label d-flex justify-content-between w-100"><span>Withdraw amount</span> <span>Min: 20.5</span></label>
                            <div className="position-relative">
                                <input type="text" className="form-control" value="0.00000" />
                                <div className="d-flex withdraw-amount-block bg-light rounded border">
                                    <div className="withdraw-am-inner">Min</div>
                                    <div className="withdraw-am-inner border-start">25%</div>
                                    <div className="withdraw-am-inner border-start">50%</div>
                                    <div className="withdraw-am-inner border-start">Max</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="d-flex border rounded p-3 bg-light flex-wrap">
                            <p className="form-label d-flex justify-content-between w-100">Withdraw amount: <strong>0.000 MATIC</strong></p>
                            <p className="form-label d-flex justify-content-between w-100">Fee: <strong>0.5 MATIC</strong></p>
                            <p className="form-label d-flex justify-content-between w-100 m-0">Total Withdraw amount: <strong style={{color:'green'}}>0.000 MATIC</strong></p>
                        </div>
                        <div className="w-100 rounded p-2 mt-4" style={{border: '1px solid #c72b2c', background: 'rgb(242, 227, 202)'}}>
                            <p className="m-0 text-secondary text-center" style={{fontSize: '12px'}}>
                                <strong className="primeColor">Notice: </strong>
                                For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patiencel
                            </p>
                            </div>
                        <div className="text-center mt-4">
                            <button className="w-100 allBtn ms-1">Confirm</button>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </div>
        </>
    )
}