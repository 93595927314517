import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value: null
}

export const socketConnection = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { setSocket } = socketConnection.actions;
export default socketConnection.reducer;