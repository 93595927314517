import React, { useState } from "react";
import LoginButton from "./loginButton";
import Logo from "./logo";
import Language from "./language";
import { Link } from "react-router-dom";
import HeaderMenu from "./headerMenu";
import LanguageSelect from "./languageSelect";
import { useTranslation } from "react-i18next";


const MainHeader = () => {

  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid header">
        {/* {t('Dashbord')} */}
        <div className="row justify-content-between align-items-center">
          <Logo />
          <div className="w-auto">
            <div className="row header-form m-0 align-items-center">
              <LoginButton />
              {/* <Language /> */}
              <HeaderMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHeader;
