import React from "react";
import MainHeader from "../components/header/mainHeader";
import PrimaryNavigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function ContactUs() {
    const owlCarouselOptions = {
        items: 1,
        margin: 0,
        nav: false,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 2000,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-3 mb-3">
                <div className="container">

                    <div className="row">
                        <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                            <p className="box-title text-white">Contact Us</p>
                            <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                <i className="fa-solid fa-xmark"></i>
                            </Link>
                        </header>
                        <div className="box-block position-relative des-content-block">
                            <div class="row">
                                <div class="col-md-12 contact">
                                    <p>We encouraged our players to interact with us by way of comments, reviews, feedback, questions, concerns or complaints of any issues they may face on www.Card Flix.com Every interaction is examined and due responses are provided by our team. We assure you that our team is committed to providing you with exceptional service and we will get back to you as soon as possible.</p>
                                </div>
                            </div>

                            <div class="row mt-3 align-items-center">
                                <div class="col-md-6 mb-4">
                                    <div class="w-100">
                                        <h3 className="sub-title-text">Contact Form</h3>
                                        <p>Contact us with your query. We would be happy to answer your questions.</p>
                                        <form>
                                            <div class="mb-3">
                                                <label for="" class="form-label"> Name: </label>
                                                <input type="text" class="form-control" id="" />
                                            </div>
                                            <div class="mb-3">
                                                <label for="" class="form-label">  Email:  </label>
                                                <input type="email" class="form-control" id="" />
                                            </div>
                                            <div class="mb-3">
                                                <label for="" class="form-label">   Phone:  </label>
                                                <input type="text" class="form-control" id="" />
                                            </div>
                                            <div class="mb-3">
                                                <label for="" class="form-label"> Message:</label>
                                                <textarea class="form-control " id="message" rows="5"></textarea>
                                            </div>
                                            <button type="submit" class="allBtn" style={{ minWidth: '100px' }}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className='w-100 bannerSlide'>
                                        <OwlCarousel className='owl-theme position-relative' {...owlCarouselOptions}>

                                            <div className="item">
                                                <img src="assets/img/contact2.png" className="w-100 mt-0 mb-0 " alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="assets/img/contact1.png" className="w-100 mt-0 mb-0" alt="" />
                                            </div>

                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}