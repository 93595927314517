import React from 'react'
import { Link } from 'react-router-dom'

function Logo() {
  return (
    <div className="w-auto">
    <Link to="/">
        <img src="assets/img/logo.png" className="logo" width="100" />
    </Link>
    </div>
  )
}

export default Logo