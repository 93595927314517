import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// import Backend from "i18next-xhr-backend";
import Backend from "i18next-http-backend"

import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./../public/locals/en.json";
import translationhindi from "./../public/locals/hindi.json";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["hd"];
const availableLanguages = ["en", "hd"];
const resources = {
    en: {
        translation: translationEN
    },
    hd: {
        translation: translationhindi
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;