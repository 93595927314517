import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value1: Date.now(),
    value2: true
}

const lastRefreshed = createSlice({
    name: 'lastRefreshed',
    initialState,
    reducers: {
        whenLastRefreshed: (state) => {
            const a = (Date.now() - state.value1) / 1000 > 10;
            if (a) {
                state.value1 = Date.now()
                state.value2 = true
                return;
            } else {
                state.value2 = false
            }
        }
    }
})

export const {whenLastRefreshed} = lastRefreshed.actions
export default lastRefreshed.reducer