import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useCheckLogin from '../Middleware/useCheckLogin';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryNavigation from './navigation/navigation';
import MainHeader from './header/mainHeader';
import Footer from './footer/footer';
import { useTransactionMutation } from '../redux/Services/apis';
import { Bounce, toast } from 'react-toastify';

export default function TransactionHistory() {

    const isLogin = useSelector(state => state.login.value);

    useCheckLogin('/history')

    const [transaction, { data, isSuccess }] = useTransactionMutation()

    const [pageNumber, setPageNumber] = useState(1)
    const [type, setType] = useState('all')

    useEffect(() => {
        const playerId = JSON.parse(localStorage.getItem('playerId'))
        const oprId = JSON.parse(localStorage.getItem('oprId'))
        transaction({ playerId, oprId })
            .then(e => console.log(e.data))
    }, [])

    useEffect(() => {
        if (data?.message === 'transaction history not found') { }
    }, [data, isSuccess])

    const changePage = (str) => {
        if (str === 'next' && (pageNumber < (data?.data?.length / 10) - 1)) {
            setPageNumber(pageNumber + 1)
        } else if (str === 'prev' && pageNumber !== 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const handleChange = (e) => {
        setType(e.target.value)
    }

    useEffect(() => {
        if (type !== 'all') {
            toast.warn('No data found', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }, [type])

    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-md-3 mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                <p className="box-title text-white">View Transactions</p>
                                <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                    <i className="fa-solid fa-xmark"></i>
                                </Link>
                            </header>
                        </div>
                        <div className="box-block position-relative">
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-50">
                                        <select
                                            className="form-select transactions-select"
                                            aria-label="Default select example"
                                            onChange={handleChange}
                                        >
                                            <option value={'all'}>All Transactions</option>
                                            <option value={'deposits'}>Deposits and Withdrawals</option>
                                            <option value={'game'}>Game Transactions</option>
                                            <option value={'bonus'}>Bonuses</option>
                                            <option value={'cashback'}>Cash offer and Cashback</option>
                                            <option value={'pending'}>Pending/Rejected Transactions</option>
                                            <option value={'other'}>Other Transactions</option>
                                        </select>
                                    </div>
                                    <div className="w-100 mt-3">
                                        <div className='w-100 overflow-auto'>
                                            <table className="table table-bordered w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Date </th>
                                                        <th>Time</th>
                                                        <th>Remark (Description)</th>
                                                        <th>Transaction Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.data?.length > 0 && type === 'all' && data.data.map((transaction, i) => (
                                                        (data.data.length < 10 || (i >= pageNumber * 10 && i < pageNumber * 10 + 10)) && <tr key={i}>
                                                            <td>{transaction.created_on.split(',')[0]}</td>
                                                            <td>{transaction.created_on.split(',')[1]}</td>
                                                            <td>{transaction.game_code}</td>
                                                            <td className={(transaction.transaction_type === "1" || transaction.transaction_type === "4") ? 'text-danger' : transaction.transaction_type === "2" ? 'text-success' : 'text-warning'}>{transaction.transaction_type === "1" ? '-' : "+"}{transaction.amount}</td>
                                                        </tr>
                                                    ))}
                                                    {data?.data?.length === 0 && <tr>
                                                        <td colSpan={5} className="text-center f-12">
                                                            {data?.message === 'transaction history not found' && 'You do not have any transactions in your account'}
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>

                                        {data?.data?.length > 0 && <>
                                            <div className='table-pagination'>
                                                <button onClick={() => changePage('prev')}>prev</button>
                                                <span className='text-dark'>{pageNumber}/{(data?.data?.length / 10).toFixed(0)}</span>
                                                <button onClick={() => changePage('next')}>next</button>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
