import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useCheckLogin from '../Middleware/useCheckLogin';
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from './header/mainHeader';
import PrimaryNavigation from './navigation/navigation';
import Footer from './footer/footer';
import { useDepositDetailsMutation, useKvmPayMutation } from '../redux/Services/apis';

export default function AddCash() {


    const [depositCash, { data, isSuccess, reset }] = useDepositDetailsMutation({ fixedCacheKey: 'transaction' })
    const [kvmPay, { reset: kvmDataReset }] = useKvmPayMutation({ fixedCacheKey: 'kvm' })

    const [cash, setCash] = useState('');
    const [activeCash, setActiveCash] = useState(null);
    const [payVia, setPayVia] = useState('manual')
    const [mobile, setMobile] = useState(7575757583)
    const [email, setEmail] = useState('hello@gmail.com')

    const handleCashClick = (amount) => {
        setCash(amount);
        setActiveCash(amount);
    };

    const isLogin = useSelector(state => state.login.value);
    const navigate = useNavigate()

    useCheckLogin('/addCash')

    useEffect(() => {
        reset()
        kvmDataReset()
    }, [])

    useEffect(() => {
        if (data && isSuccess) {
            localStorage.setItem('amount', cash)
            navigate('/transaction-qr')
        }
    }, [data, isSuccess])

    const payViaKVM = () => {
        if (!mobile || !email || !cash) return
        const id = Date.now().toString()
        localStorage.setItem('amount', cash)
        kvmPay({ amount: cash, email, mobile, id })
            .then((e) => {
                if (e.data.status) {
                    navigate('/kvmPay')
                }
            })
    }

    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-md-3 mb-3">
                {isLogin && isLogin !== 'otp' && <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                <p className="box-title text-white">Add Cash</p>
                                <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                    <i className="fa-solid fa-xmark"></i>
                                </Link>
                            </header>
                        </div>
                        <div className="box-block position-relative">
                            <div className="row mt-3 m-0">
                                <div className="col-md-12 bg-light rounded p-4 border">
                                    <div className="row">
                                        <div className="col-md-3 mb-3 mb-md-0 d-none d-md-block">
                                            <img className="w-100 rounded" src="assets/img/ads.jpg" alt='' />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        {[10, 20, 50, 100, 500, 1000].map(amount => (
                                                            <div key={amount} className={`col-6 mb-3 ${activeCash === amount ? 'addcashtiles-active' : ''}`} onClick={() => handleCashClick(amount)}>
                                                                <div className="border addcashtiles rounded bg-white p-2 py-3">
                                                                    <p className="text-center m-0 text-black">
                                                                        <strong className="text-center d-block text-black">
                                                                            ₹{amount}
                                                                        </strong>
                                                                        <span className="text-secondary">Bonus ₹1000</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Enter Amount </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={cash}
                                                            name=""
                                                            onChange={e => setCash(e.target.value)}
                                                        />
                                                        <div
                                                            id="emailHelp"
                                                            className="form-text d-flex justify-content-between flex-wrap"
                                                        >
                                                            Bonus ₹ 1000 <a href="#">Includes Deposit &amp; GST</a>
                                                        </div>
                                                    </div>
                                                    {payVia === 'kvm' && <>
                                                        <div className="mb-3">
                                                            <label className="form-label">Enter Mobile </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                value={mobile}
                                                                name=""
                                                                onChange={e => e.target.value.length <= 10 && setMobile(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Enter Email </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={email}
                                                                name=""
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </>}
                                                    <div className="mb-3 d-flex manual-add-btn">
                                                        {payVia !== 'kvm' && <button className="w-100 allBtn" onClick={() => { depositCash() }}>Manual Add</button>}
                                                        {payVia !== 'kvm' && <button className="w-100 allBtn ms-1" onClick={() => setPayVia('kvm')} >KVMPay</button>}
                                                        {payVia === 'kvm' && <button className="w-100 allBtn ms-1" onClick={payViaKVM}>Confirm</button>}
                                                        {payVia !== 'kvm' && <button className="w-100 allBtn ms-1" disabled>CoinPay</button>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="border-bottom d-flex justify-content-center align-items-center text-black pb-2">
                                                            <img
                                                                className="me-2"
                                                                src="assets/img/secure.png"
                                                                width={30} alt=''
                                                            />{" "}
                                                            100% SECURE
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 bg-white rounded p-2 border">
                                                    <p
                                                        className="m-0 text-secondary text-center"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        For cash play restrictions, refer to{" "}
                                                        <a href="#" className="primeColor">
                                                            Terms of Service
                                                        </a>{" "}
                                                        | Bonus credit is subject to{" "}
                                                        <a href="#" className="primeColor">
                                                            {" "}
                                                            Terms and Conditions
                                                        </a>{" "}
                                                        | Learn more about your{" "}
                                                        <a href="#" className="primeColor">
                                                            Add Cash Limits
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div
                                                    className="col-12 rounded p-2"
                                                    style={{
                                                        border: "1px solid #f2e3ca",
                                                        background: "#f2e3ca"
                                                    }}
                                                >
                                                    <p
                                                        className="m-0 text-secondary text-center"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        Assam, Telangana and Andhra Pradesh players are not
                                                        allowed to play cash games.{" "}
                                                        <a href="#" className="primeColor">
                                                            T&amp;C
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Footer />
        </>
    )
}
