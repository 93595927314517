import React, { useEffect, useState } from 'react'
import { useFetchAvatarMutation, useUpdateAvatarMutation, useUploadImageMutation } from '../redux/Services/apis';
import { useDispatch, useSelector } from 'react-redux';
import { profilePicture } from '../redux/slices/userCurrentPFP.slice';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import useCheckLogin from '../Middleware/useCheckLogin';
import { uName } from '../redux/slices/userName.slice';
import Footer from '../components/footer/footer';
import MainHeader from '../components/header/mainHeader';
import PrimaryNavigation from '../components/navigation/navigation';

export default function InfoForm() {

    const nickName = JSON.parse(localStorage.getItem('nickName'));

    const dispatch = useDispatch()

    const [image, setImage] = useState('');
    const [imageName, setImageName] = useState('');
    const [avtID, setAvtID] = useState('');
    const [avtURL, setAvtURL] = useState('');
    const [selectedAvatar, setSelectedAvatar] = useState(-1);
    const [name, setName] = useState(nickName)
    const [email, setEmail] = useState('')

    const [uploadImage, { isSuccess: pfpSuccess }] = useUploadImageMutation();
    const [fetchAvatar, { data: avatars }] = useFetchAvatarMutation();
    const [updateAvatar, { isSuccess: updateSuccess }] = useUpdateAvatarMutation();

    const isLogin = useSelector(state => state.login.value);
    const imageURL = useSelector(state => state.pfp.value.avatar);
    const imageId = useSelector(state => state.pfp.value.avatar_id);

    useCheckLogin('/info')

    const convertImageToBase64 = (e) => {
        const reader = new FileReader();
        console.log(e);
        if (e.target.files.length === 0) {
            return;
        }
        reader.readAsDataURL(e.target?.files?.[0])
        const name = e.target.files[0].name
        setImageName(name)
        reader.onload = () => {
            const img = reader.result.split(',')[1];
            setImage(img);
        }
    }

    const updateUserAvatar = () => {
        if (image) {
            const playerId = JSON.parse(localStorage.getItem('playerId'))
            const oprId = JSON.parse(localStorage.getItem('oprId'))
            uploadImage({ image, imageName, playerId, oprId })
                .then(res =>
                    (console.log(res)),
                    toast.success('Image Uploaded', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                )
            setImage('')
            setImageName('')
        } else {
            setSelectedAvatar(-1)
            const playerId = JSON.parse(localStorage.getItem('playerId'));
            if (playerId && nickName && avtURL && avtID) {
                updateAvatar({ playerId, nickName, avtURL, avtID })
                    .then(res => (console.log(res),
                        toast.success('Avatar Updated', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                    ))
                setAvtID('')
                setAvtURL('')
            } else if (playerId && name && imageURL && imageId) {
                console.log('name', name);
                updateAvatar({ playerId, nickName: name, avtURL: imageURL, avtID: imageId })
                    .then(res => {
                        console.log(res)
                        toast.success('Nick name Updated successfully', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        localStorage.setItem('nickName', JSON.stringify(name))
                        dispatch(uName(name))
                    })
            }
        }
    }

    useEffect(() => {
        if (isLogin) {
            const avtarBack = document.querySelector('.avtarBack');
            const avatarBack = document.querySelector('#avatarBack');
            const avtarSection = document.querySelector('.avtar-section');
            const avtarBlock = document.querySelectorAll('.avtar-block');
            const cameraBtn = document.querySelector('.cameraBtn');

            avtarBack.addEventListener('click', () => {
                avtarSection.style.display = "none";
            });
            avatarBack.addEventListener('click', () => {
                avtarSection.style.display = "none";
            });
            avtarBlock.forEach(avtarDiv => {
                avtarDiv.addEventListener('click', () => {
                    avtarBlock.forEach(div => {
                        div.classList.remove('select-avtar');
                    });
                    avtarDiv.classList.add('select-avtar');
                });
            });
            cameraBtn.addEventListener('click', () => {
                avtarSection.style.display = "flex";
            });
        }
    }, [isLogin])

    useEffect(() => {
        const playerId = JSON.parse(localStorage.getItem('playerId'))
        const operatorId = JSON.parse(localStorage.getItem('oprId'))
        if (playerId && operatorId && isLogin) {
            fetchAvatar({ playerId, operatorId })
                .then(res => (dispatch(profilePicture(res.data?.data?.profile_arr[0]))))
        }
    }, [isLogin, updateSuccess, pfpSuccess])

    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-md-3 mb-3">
                {isLogin && isLogin !== 'otp' && <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                <p className='box-title text-white'>My Profile</p>
                                <Link to="/" className="btn-close text-reset text-white page-close">
                                    <i className="fa-solid fa-xmark"></i>
                                </Link>
                            </header>
                        </div>
                        <div className="col-md-6 mb-md-0 mb-0 profile-start-box" style={{ minHeightheight: '500px' }}>
                            <div className="box-block h-100 position-relative border_radius_mb_none">
                                <div className="row align-items-center profile_game_point">
                                    <div className="col-md-6 col-6 mb-0 mb-md-0">
                                        <div className="club-point rounded bg-light d-flex justify-content-between align-items-center border ps-2">
                                            Club{" "}
                                            <span className="ms-1">
                                                <img
                                                    className="me-1"
                                                    src="assets/img/silver.png"
                                                    width="20px"
                                                    alt=''
                                                />{" "}
                                                45
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <div className="club-point rounded bg-light d-flex justify-content-between align-items-center border ps-2">
                                            VIP Points <span className="ms-1">107</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 mb-3">
                                        <h3 className="text-center username-profile text-dark">{nickName}</h3>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-4">
                                    <div className="w-auto position-relative">
                                        <img className="user-profile-img" src={imageURL ? imageURL : "assets/img/user.png"} alt='profilePicture' />
                                        <button className="cameraBtn position-absolute bg-white border border-dark">
                                            <i className="fa-solid fa-pen text-dark"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label className="form-label">Nickname</label>
                                        <div className="d-flex email-block-btn position-relative user_email_mb_btn">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name=""
                                                value={name}
                                                maxLength={17}
                                                onChange={e => setName(e.target.value)}
                                                // onChange={e => e.target.value.length <= 17 && setName(e.target.value)}
                                            />
                                            <button className="allBtn confirmEmailBtn ms-0 ms-md-3 mt-3 mt-md-0" onClick={updateUserAvatar}>
                                                Confirm User
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label className="form-label">Email</label>
                                        <div className="d-flex email-block-btn position-relative user_email_mb_btn">
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                name=""
                                            />
                                            <button className="allBtn confirmEmailBtn ms-0 ms-md-3 mt-3 mt-md-0">
                                                Confirm email
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 position-absolute top-0 h-100 bg-white start-0 avtar-section p-3 rounded pt-3" style={{ flexDirection: ' column' }}>
                                    <h3 className="text-center box-title mb-4">Select Avatar</h3>
                                    <div className="row overflow-auto" style={{ flex: "1 1 auto" }}>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-md-3 col-4 gallery-btn-block d-flex mb-3'>
                                                    <button className="gallery-btn position-relative">
                                                        <img src="assets/img/Gallery.png" width={20} alt='' /> Gallery
                                                        <input
                                                            className="position-absolute top-0 bottom-0 start-0 end-0"
                                                            type="file"
                                                            accept='image/*'
                                                            onChange={convertImageToBase64}
                                                        />
                                                    </button>
                                                </div>
                                                {/* <div className='col-md-9'>
                                        <div className='row'> */}
                                                {avatars?.data?.profile_arr.map((avt, i) => (
                                                    <div className={`col-md-3 mb-3 avtar-block col-4 ${selectedAvatar === i && 'select-avtar'}`} key={i} onClick={() => (setAvtID(avt.avatar_id), setAvtURL(avt.avatar), setSelectedAvatar(i))}>
                                                        <div className="w-100 bg-light border rounded p-2">
                                                            <img className="w-100" src={avt.avatar} alt='avatar' style={{ 'minHeight': '69px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-3">
                                        <div className="w-auto d-flex">
                                            <button className="allBtn me-2" id='avatarBack' style={{ minWidth: 114 }} onClick={updateUserAvatar}>
                                                SAVE
                                            </button>
                                            <button
                                                className="btn btn-secondary avtarBack"
                                                style={{ minWidth: 114 }}
                                            >
                                                BACK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 profile-end-box">
                            <div className="box-block h-100 bg-white border_radius_mb_bottom">

                                {/* Personal Information */}
                                <h2 className="box-title mb-3 mt-md-2 mt-0 pt-md-0 pt-2">Personal Information</h2>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label d-block">Birthday</label>
                                        <input className="form-control" type="date" />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label d-block">Phone Number</label>
                                        <input className="form-control" type="number" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label d-block">City</label>
                                        <input className="form-control" type="text" />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label d-block">State</label>
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <button className="allBtn">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Footer />
        </>
    )
}
