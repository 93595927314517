import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apis = createApi({
    reducerPath: 'apis',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_URL_PORT}` }),
    endpoints: (builder) => ({
        mobileLogin: builder.mutation({
            query: ({ phoneNo, oprId: operator, aff_code = '', referred_by = '' }) => {
                return {
                    url: 'login/mobile',
                    method: 'POST',
                    body: {
                        country_code: "+91",
                        mobile_number: phoneNo,
                        device_details: {
                            device_id: ""
                        },
                        affiliate_id: "",
                        referral_code: "",
                        promo_code: "",
                        operator_id: operator,
                        aff_code,
                        referred_by
                    },
                    headers: {
                        operator_id: operator,
                    }
                }
            }
        }),
        mobileConfirm: builder.mutation({
            query: (body) => {
                return {
                    url: 'login/mobile-confirm',
                    method: 'POST',
                    body,
                }
            }
        }),
        emailLogin: builder.mutation({
            query: ({ email, oprId: operator }) => {
                return {
                    url: 'login/email',
                    method: 'POST',
                    body: {
                        country_code: "+91",
                        email: email,
                        currency: "INR",
                        device_details: {
                            device_id: ""
                        },
                        affiliate_id: "",
                        referral_code: "",
                        promo_code: "",
                        operator_id: operator,
                    },
                    headers: {
                        operator_id: operator,
                    }
                }
            }
        }),
        emailConfirm: builder.mutation({
            query: (body) => {
                console.log(body);
                return {
                    url: 'login/email-confirm',
                    method: 'POST',
                    body,
                }
            }
        }),
        gameList: builder.mutation({
            query: ({ oprId, playerId, token }) => {
                return {
                    url: 'api/game-lists',
                    method: 'POST',
                    body: {
                        integration_env: "H5",
                        operator_id: oprId,
                        player_id: playerId
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            }
        }),
        uploadImage: builder.mutation({
            query: ({ image, imageName, playerId, oprId }) => {
                return {
                    url: 'update/profilePicture',
                    method: 'POST',
                    body: {
                        image: image,
                        imageName: imageName,
                        player_id: playerId,
                        operator_id: oprId
                    }
                }
            }
        }),
        fetchAvatar: builder.mutation({
            query: ({ playerId, operatorId }) => {
                return {
                    url: '/fetch/userAvatar',
                    method: 'POST',
                    body: {
                        player_id: playerId,
                        operator_id: operatorId
                    }
                }
            }
        }),
        updateAvatar: builder.mutation({
            query: ({ playerId, nickName, avtURL, avtID }) => {
                return {
                    url: 'update/userAvatar',
                    method: 'POST',
                    body: {
                        player_id: playerId,
                        nickName: nickName,
                        avatar_url: avtURL,
                        profile_type: 'default',
                        avatar_id: avtID
                    }
                }
            }
        }),
        depositCash: builder.mutation({
            query: ({ playerId, amount, ID }) => {
                return {
                    url: 'transaction/deposit',
                    method: 'POST',
                    body: {

                        operator_id: process.env.REACT_APP_OPERATOR_ID,
                        player_id: playerId,
                        device_id: "1",
                        deposit_method: "upi-QR",
                        transaction_id: ID,
                        amount: amount,
                        "payment_gateway": "manual"
                    }
                }
            }
        }),
        refreshToken: builder.mutation({
            query: ({ playerId, oprId, token }) => {
                return {
                    url: 'auth/game/token',
                    method: 'POST',
                    body: {
                        operator_id: oprId,
                        player_id: playerId,
                        current_auth_token: token
                    }
                }
            }
        }),
        dummyGames: builder.mutation({
            query: ({ oprId }) => {
                return {
                    url: 'api/web/game-lists',
                    method: 'POST',
                    body: {
                        operator_id: oprId,
                        integration_env: "H5"
                    }
                }
            }
        }),
        kyc: builder.mutation({
            query: ({ oprId, playerId, kycType, docName, frontImage, backImage, docNum }) => {
                return {
                    url: 'save/kycdetails',
                    method: 'POST',
                    body: {
                        "operator_id": oprId,
                        "player_id": playerId,
                        "doc_type": kycType,
                        "player_name": docName,
                        "front_img": frontImage,
                        "back_img": backImage,
                        "doc_number": docNum
                    }
                }
            }
        }),
        transaction: builder.mutation({
            query: ({ playerId, oprId }) => {
                return {
                    url: 'transaction/history',
                    method: 'POST',
                    body: {
                        "operator_id": oprId,
                        "player_id": playerId
                    }
                }
            }
        }),
        kvmPay: builder.mutation({
            query: ({ amount, email, mobile, id }) => {
                return {
                    url: 'pg/kvmpay-deposit',
                    method: 'POST',
                    body: {
                        "player_id": JSON.parse(localStorage.getItem('playerId')),
                        "operator_id": process.env.REACT_APP_OPERATOR_ID,
                        "device_id": id,
                        "deposit_method": "UPI",
                        "amount": Number(amount),
                        "mobileNo": mobile,
                        "email": email
                    }
                }
            }
        }),
        depositDetails: builder.mutation({
            query: () => {
                return {
                    url: 'fetch/deposit-details',
                    method: 'POST',
                    body: {
                        "operator_id": process.env.REACT_APP_OPERATOR_ID,
                        "country": "+91",
                        "deposit_method": "upi-QR"
                    }
                }
            }
        }),
        kvmStatus: builder.mutation({
            query: ({ transactionId }) => {
                return {
                    url: 'pg/kvmpay-checkStatus',
                    method: 'POST',
                    body: {
                        transaction_id: transactionId
                    }
                }
            }
        })
    }),
})
export const { useGameListMutation, useMobileConfirmMutation, useMobileLoginMutation, useUploadImageMutation, useFetchAvatarMutation, useUpdateAvatarMutation, useEmailLoginMutation, useEmailConfirmMutation, useRefreshTokenMutation, useDummyGamesMutation, useKycMutation, useTransactionMutation, useKvmPayMutation, useDepositCashMutation, useDepositDetailsMutation, useKvmStatusMutation } = apis