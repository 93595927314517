import React, { useEffect, useState } from 'react'
import '../transaction-qr.css'
import { useDepositCashMutation, useTransactionMutation } from '../redux/Services/apis';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function TransactionQR() {

    const data = useSelector(state => state.apis?.mutations?.transaction?.data?.data)

    const [depositCash, { data: depositCashData, isSuccess }] = useDepositCashMutation()

    const [ID, setID] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (!data) navigate('/addCash')
    }, [])
    useEffect(() => {
        if (depositCashData?.status && isSuccess) {
            toast.success("Your Deposit Request Submitted Successfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            navigate('/addCash')
        }
    }, [depositCashData])

    const submit = () => {
        const playerId = JSON.parse(localStorage.getItem('playerId'))
        const amount = localStorage.getItem('amount')
        depositCash({ playerId, amount, ID })
    }

    return (
        <div className="w-100 fullpage flex-wrap" style={{minHeight:'100vh'}}>
            <div className="back-top w-100" onClick={() => navigate('/addCash')} style={{height:'55px'}}>
                    <i className="fa-solid fa-chevron-left" />
                </div>
        <div className='container'>
        {data ? <div className='row my-3 my-lg-0'>
                <div className='col-md-6 pe-md-5 border-end'>
                    
                <div className="mb-3 w-100">
                        <label className="form-label">Method</label>
                        <div className="position-relative">
                            <select className="form-control ps-5">
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                            </select>
                            <img src="assets/img/phonepe.png" className="method-icon" />
                        </div>
                    </div>
                    <div className="mb-3 w-100">
                        <label className="form-label">Amount</label>
                        <div className="position-relative">
                            <input type="text" className="form-control ps-5" defaultValue={localStorage.getItem('amount')} disabled name="" />
                            <img src="assets/img/ruppe.png" className="method-icon" />
                        </div>
                    </div>
                    <div className="mb-3 w-100">
                        <label className="form-label">Transaction id/UTR</label>
                        <div className="position-relative">
                            <input type="text" className="form-control" value={ID} onChange={(e) => setID(e.target.value)} name="" />
                            {/* <img src="assets/img/ruppe.png" className="method-icon" /> */}
                        </div>
                    </div>

                    <label className="form-label"><strong style={{color:'#671718'}}>Instructions</strong></label>
                    <div className="w-100 instructions-block">
                        <div className="step-text">
                            <strong>Step 1: </strong>
                            Take screenshot of QR code or copy the UPI ID
                        </div>
                        <div className="step-text">
                            <strong>Step 2: </strong>
                            Gp to UPI app and scan the QR code or paste the UPI ID
                        </div>
                        <div className="step-text">
                            <strong>Step 3: </strong>
                            After successful payment enter the transaction ID/UTR
                        </div>
                        <div className="step-text">
                            <strong>Step 4: </strong>
                            Click on submit: payment will be added instantly or in 3-5 mins.
                        </div>
                        
                    </div>
                    
                </div>
                <div className='col-md-6'>
                <div className="d-flex" style={{ marginTop: 30 }}>
                            <div className="qr-code">
                                <img src={data.deposit_details[0].QR_url} />
                            </div>
                        </div>
                        <div className="d-flex" style={{ marginTop: 30 }}>
                            <div className="upiid-text">UPI IDs:</div>
                            <div className="upiid-text-text">
                                {data.deposit_details?.map((e, i) => (
                                    <p key={i}>
                                        {e.upi_id} <i className="fa-solid fa-copy" />
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className="text-ceter">
                            <button className="submit-btn" onClick={submit}>Submit</button>
                            <button className="submit-btn support-btn ms-3">Support</button>
                        </div>
                    
                </div>

            </div> : ''}
        </div>
            {/* {data ? <div div className="w-3" >
                <div className="back-top" onClick={() => navigate('/addCash')}>
                    <i className="fa-solid fa-chevron-left" />
                </div>
                
                <div className="w-100 px-3">

                    
                </div>
            </div > : ''} */}
        </div >


    )
}
