import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const info = ["Home", "Promotions", "Download App"];
//const infoNew = ["Contact US", "Login & Play", "Sign Up & Play"];

const Info = () => {
  const isLogin = useSelector(state => state.login?.value);
  const modalAttributes = isLogin && isLogin !== 'otp' ? {
  } : {
    'data-bs-toggle': 'modal',
    'data-bs-target': '#loginModal'};
  return (
    <>
    <div className="col-md-3">
      <h2 className="footer-title position-relative mb-4">About </h2>
      <ul className="footer-list p-0 mb-0">
        
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/promotions">Promotions</Link>
            </li>
            <li>
              <a href="#">Download App</a>
            </li>
      </ul>
      {/* <ul className="footer-list p-0 mb-0">
        {info.map((data, i) => {
          return (
            <li key={i}>
              <a href="/">{data}</a>
            </li>
          );
        })}
      </ul> */}
    </div>
    <div className="col-md-3">
      <h2 className="footer-title position-relative mb-4 d-none d-md-block" style={{color:'#101213'}}>About </h2>
      <ul className="footer-list p-0 mb-0">
            <li>
              <Link to="/contact-us">Contact US</Link>
            </li>
            <li>
              <a href="javascript:void(0)" {...modalAttributes}>Login & Play</a>
            </li>
            {/* <li>
              <a href="#">Sign Up & Play</a>
            </li> */}
      </ul>
    </div>
    </>
  );
};

export default Info;
