import React from 'react';
import { Link } from 'react-router-dom';

const navigationOption = ['Ludo', 'Rummy', 'Poker', 'Teen Patti', 'RNG Games'];

const PrimaryNavigation = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light w-100 header-navbar p-0 d-none d-lg-flex">
      <div className="container-fluid second_nav">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav d-flex align-items-center flex-wrap justify-content-between w-100">
            {/* <li className="nav-item">
              <Link className="nav-link " to="/">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li> */}

            <li className="nav-item">
              <Link className="nav-link" href="/"><img src='assets/img/chip.png' /> Poker</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="/"><img src='assets/img/ludo.png' /> Ludo</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="/"><img src='assets/img/symbol.png' /> Rummy</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="/"><img src='assets/img/playing-cards.png' /> Teen Patti</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="/"><img src='assets/img/poker-chips.png' /> RNG Games</Link>
            </li>

            {/* {navigationOption.map((option, i) => (
              <li className="nav-item" key={i}>
              <Link className="nav-link" href="/">{option}</Link>
            </li>
            ))} */}
            {/* <li className="nav-item">
              <a className="downloadbtn" href="/vip">
                <i className="fa-solid fa-download"></i> Download Now
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PrimaryNavigation;
