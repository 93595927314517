import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: ''
}

export const profileImage = createSlice({
    name: 'pfPicture',
    initialState,
    reducers: {
        profilePicture: (state, action) => {
            const payload = action.payload
            state.value = payload
        }
    }
});

export const { profilePicture } = profileImage.actions
export default profileImage.reducer