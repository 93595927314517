import React, { useEffect, useState } from "react";
import './formstyle1.css';
// import Logo from '/public/assets/img/logo.png';
import { useMobileConfirmMutation, useMobileLoginMutation } from "../../redux/Services/apis";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/slices/isLoggedIn.slice";
import { toast } from "react-toastify";
import { uName } from "../../redux/slices/userName.slice";
import { useLocation, useNavigate } from "react-router-dom";

let interval;
export default function Signup() {
    const [mobileLogin, { data: mobileData, error: loginError }] = useMobileLoginMutation();
    const [mobileConfirm, { error: confirmError }] = useMobileConfirmMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phoneNo, setPhoneNo] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState("");
    const [mobileSuccess, setMobileSuccess] = useState('otpNotSend');
    // const [time, setTime] = useState(30)
    // const [timerRunning, setTimerRunning] = useState(false);
    const isLogin = useSelector(state => state.login.value);

    const query = useLocation().search.split('?')[1] || '';

    useEffect(() => {
        document.querySelector('body').style.paddingTop = "0";
        document.querySelector('body').style.background = "#fff";
    }, []);

    useEffect(() => {
        console.log('mobileData', mobileData);
        if (mobileData?.message === "OTP SENT SUCCESSFULLY (New User)" || mobileData?.message === "OTP SENT SUCCESSFULLY (Existing User)") {
            setMobileSuccess('otpSent');
        }
        // else if (emailData?.message === "OTP SENT SUCCESSFULLY (New User)" || emailData?.message === "OTP SENT SUCCESSFULLY (Existing User)") {
        //     confirmEmail()
        // }
    }, [mobileData])

    useEffect(() => {
        if (isLogin === false) {
            setMobileSuccess('otpNotSend')
        }
    }, [isLogin])

    // Function to start the countdown
    // function startCountdown() {
    //     clearInterval(interval);
    //     interval = setInterval(() => {
    //         if (time > 0) {
    //             console.log('running', time);
    //             setTimerRunning(true);
    //             setTime((time) => time - 1);
    //             startCountdown()
    //         }
    //     }, 1000); // Update every 1000 milliseconds (1 second)
    // }
    // console.log(time);

    // useEffect(() => {
    //     if (time <= 0) {
    //         setTimerRunning(false);
    //         clearInterval(interval);
    //     }
    // }, [time])

    const login = () => {
        // if (timerRunning) return;
        // setTime(30)
        // startCountdown()
        const oprId = process.env.REACT_APP_OPERATOR_ID;
        const regex = /^[0-9]{10}$/;
        if (!regex.test(phoneNo)) {
            setError('Enter valid Phone no and operator')
            setPhoneNo('')
            return
        }
        const aff_code = sessionStorage.getItem('aff_code') || '';
        console.log('aff_code', aff_code);
        mobileLogin({ phoneNo, oprId, aff_code })
            .then(res =>
                localStorage.setItem("operatorInfo", JSON.stringify(res.data)),
                dispatch(isLoggedIn("otp")),
                setError(''),
                // setPhoneNo('')
            )
            .catch(error =>
            (setPhoneNo(''),
                console.log(error),
                setError(loginError))
            )
    };

    const submitOTP = () => {
        console.log(otp);
        const operatorDetails = JSON.parse(localStorage.getItem('operatorInfo'))

        const body = {
            "otp": Number(otp),
            "_id": operatorDetails?.objectId,
            "mobile_number": operatorDetails?.mobile_number,
            "operator_id": operatorDetails?.operator_id,

        }

        const expirationDate = new Date();
        mobileConfirm(body)
            .then(
                res => {
                    console.log(res.data);
                    if (res.data.message === "Otp Mismatch") {
                        localStorage.clear()
                        toast.error('Entered wrong OTP', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        // setOtp('')
                        return
                    } else if (res.data.status === 1) {
                        expirationDate.setDate(expirationDate.getDate() + 180)
                        localStorage.setItem('token', JSON.stringify(res.data.token))
                        localStorage.setItem('authToken', JSON.stringify(res.data.authToken))
                        localStorage.setItem('oprId', JSON.stringify(res.data.opr_code))
                        localStorage.setItem('playerId', JSON.stringify(res.data.player_id))
                        localStorage.setItem('nickName', JSON.stringify(res.data.display_name))
                        localStorage.setItem('wallet_balance', JSON.stringify(res.data.wallet_balance))
                        const name = res.data.display_name
                        dispatch(uName(name))
                        // setOtp('')
                        setError('')
                        dispatch(isLoggedIn())
                        // navigate(`/?${query}`)
                        setMobileSuccess('otpVerified')
                    } else {
                        localStorage.clear()
                    }
                }
            )
            .catch(error =>
            (setError(confirmError)
                // setOtp('')
            )
            )
    }
    return (
        <>
            <div className="formFull" style={{ position: "relative" }}>
                <div className="loader" style={{ display: 'none' }}></div>
                <div className="back" style={{ display: "none" }}>
                    <button
                        id="back"
                        onClick="window.location.reload();"
                        style={{ fontSize: 0 }}
                    >
                        Back
                    </button>
                </div>
                <div className="w-100 numberSection" style={{ display: `${mobileSuccess === 'otpNotSend' ? '' : 'none'}` }}>
                    <div className="w-100" style={{ marginBottom: '50px' }}>
                        <img src='/assets/img/logo.png' />
                    </div>
                    <div className="w-100">
                        <h2 style={{ margin: "15px 0" }}>Welcome to CardFlix</h2>
                    </div>
                    <div className="validationText33" style={{ color: "red", fontSize: 12 }} />
                    <div className="w-100">
                        <input
                            type="number"
                            name="number"
                            placeholder="Enter mobile number"
                            id="mobile"
                            value={phoneNo}
                            onChange={(e) => { e.target.value?.length < 11 && setPhoneNo(e.target.value) }}
                        />
                    </div>
                    <div className="w-100">
                        <button className="submit" id="sendotp" onClick={login} disabled={phoneNo.length < 10} style={{ background: `${phoneNo.length < 10 ? 'gray' : '#ff9700'}` }}>
                            <svg viewBox="0 0 24 24">
                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="w-100 verifySection" style={{ display: `${mobileSuccess === 'otpSent' ? '' : 'none'}` }}>
                    <div className="w-100">
                        <img src='/assets/img/logo.png' style={{ marginBottom: 30 }} />
                    </div>
                    <div className="w-100">
                        <h3>Enter OTP</h3>
                        <p className="mobno_show" />
                    </div>
                    <div className="userInput">
                        <input
                            type="text"
                            id="ist"
                            maxLength={6}
                            style={{ width: "100%", margin: 0 }}
                            value={otp}
                            onChange={(e) => { setOtp(e.target.value) }}
                        />
                    </div>
                    {/* <div className="w-100">
                        <p className="resendotp">Resend OTP in {time} secc</p>
                        <p className="resend" id="resendotp" style={{ cursor: `${timerRunning ? '' : 'pointer'}` }} onClick={login} >
                            Resend
                        </p>
                    </div> */}
                    <div className="w-100">
                        <button className="verify" id="verifynow" onClick={submitOTP} disabled={otp.length === 0} style={{ background: `${otp.length === 0 ? 'gray' : '#ff9700'}` }}>
                            Verify
                        </button>
                    </div>
                </div>
                <div className="w-100 congratulation" style={{ display: `${mobileSuccess === 'otpVerified' ? '' : 'none'}` }}>
                    <div className="w-100">
                        <img src='/assets/img/logo.png' />
                    </div>
                    <div className="w-100" style={{ marginTop: '50px' }}>
                        <p style={{ fontWeight: "bold", color: "#ff9700" }}>
                            Download and Start Playing
                        </p>
                    </div>
                    <div className="w-100">
                        <a href="" id="filedownload" style={{ marginTop: 50 }} className="verify">
                            Download &amp; Play
                        </a>
                    </div>
                </div>
                <div className="w-100">
                    <p className="footer-info">
                        <span style={{ color: "#ff9700" }}>Note:</span> Please verify the same
                        number which is already registered with us.
                    </p>
                </div>
            </div>

        </>
    )
}