import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function HeaderMenu() {

  const imageURL = useSelector(state => state.pfp.value?.avatar);
  const isLogin = useSelector(state => state.login?.value);
  const userName = useSelector(state => state.userName?.value);
  const wallet = useSelector(state => state.wallet?.value);

  const [nickName, setNickName] = useState('')

  useEffect(() => {
    if (isLogin) {
      setNickName(JSON.parse(localStorage.getItem('nickName')))
    }
  }, [isLogin])

  return (
    <>
      {isLogin && isLogin !== 'otp' && <div className="w-auto d-flex align-items-center" >
        <div className="offcanvas-header me-2">
          <div className="user-offcanvas-details d-flex align-items-center">
            <div className="user-icon me-2">
              <img src={`${imageURL || "assets/img/user.png"}`} alt="" />
            </div>
            <div className="userName">
              <p className="m-0 text-white fw-bold">{userName || nickName || "please login"}<span className="user-blancer d-block text-white fw-normal font-size-14">{wallet || '00'}</span></p>
            </div>
          </div>
        </div>
      </div>}
      <div className="w-auto">
        <div className="w-100">
        {isLogin && isLogin !== 'otp' &&  <button
            className="btn text-white header-btn p-0 d-flex justify-content-center align-items-center"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasRight"
          >
            <i className="fa-solid fa-bars text-white" />
          </button>}
        </div>
      </div>
    </>
  );
}

export default HeaderMenu;
