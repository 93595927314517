import React from "react";

function MobileDownload() {
  return (
    <>
    {/* <div
      className="footer-fixed container-fluid d-flex d-md-none justify-content-center p-2 position-fixed bottom-0"
      style={{ zIndex: 9 }}
    >
      <a className="downloadbtn" href="#">
        <i className="fa-solid fa-download" /> Download Now
      </a>
    </div> */}
    </>
  );
}

export default MobileDownload;
