import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Dashboard from './pages/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './components/footer/footer';
import MainHeader from './components/header/mainHeader';
import PrimaryNavigation from './components/navigation/navigation';
import InfoForm from './pages/InfoForm';
import AddCash from './components/AddCash';
import Withdrawal from './components/Withdrawal';
import TransactionHistory from './components/TransactionHistory';
import { useEffect } from 'react';
import { useFetchAvatarMutation } from './redux/Services/apis';
import { profilePicture } from './redux/slices/userCurrentPFP.slice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Kyc from './pages/Kyc';
import GamesHistory from './components/header/GamesHistory';
import TransactionQR from './components/TransactionQR';
import KVMTransaction from './components/KVMTransaction';
import WithdrawQr from './components/WithdrawQr';
import Privacypolicy from './pages/Privacypolicy';
import Termsandconditions from './pages/Termsandconditions';
import ContactUs from './pages/ContactUs';
import Promotions from './pages/Promotions';
import ScrollToTop from './components/ScrollToTop';
import Signup from './components/signup';

function App() {

  const dispatch = useDispatch()

  const [fetchAvatar] = useFetchAvatarMutation();

  const isLogin = useSelector(state => state.login.value);

  useEffect(() => {
    if (isLogin) {
      const playerId = JSON.parse(localStorage.getItem('playerId'))
      const operatorId = JSON.parse(localStorage.getItem('oprId'))
      if (playerId && operatorId) {
        fetchAvatar({ playerId, operatorId })
          .then(res => (dispatch(profilePicture(res.data?.data?.profile_arr[0]))))
          .catch(error => console.log(error))
      }
    }
  }, [isLogin])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Dashboard />}></Route>
        <Route path='/signup' element={<Signup />} ></Route>
        <Route path='/info' element={<InfoForm />}></Route>
        <Route path='/addCash' element={<AddCash />}></Route>
        <Route path='/transaction-qr' element={<TransactionQR />} ></Route>
        <Route path='/withdraw-cash' element={<WithdrawQr />} ></Route>
        <Route path='/kvmPay' element={<KVMTransaction />} ></Route>
        <Route path='/withdrawal' element={<Withdrawal />}></Route>
        <Route path='/history' element={<TransactionHistory />}></Route>
        <Route path='/kyc' element={<Kyc />}></Route>
        <Route path='*' element={<Dashboard />}></Route>
        <Route path='/game-history' element={<GamesHistory />}></Route>
        <Route path='/privacypolicy' element={<Privacypolicy />} ></Route>
        <Route path='/termsandconditions' element={<Termsandconditions />} ></Route>
        <Route path='/contact-us' element={<ContactUs />} ></Route>
        <Route path='/promotions' element={<Promotions />} ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
