import React from "react";

import i18next from "i18next";

const language = [
  {
    countryName: "India",
    src: "img/india.png",
    langCode: {
      en: {
        label: "/flags/Flag_of_United_Kingdom_Flat_Wavy-64x37.png",
        name: 'English',
        dir: "ltr",
        active: true,
      }
    },
    langHindi: "हिन्दी",
    langEnglish: "English",
    langNative: "हिन्दी",
  },
  {
    countryName: "India",
    src: "img/india.png",
    langCode: {
      hd: {
        label: "/flags/Flag_of_India_Flat_Wavy-64x49.png",
        name: 'हिंदी',
        dir: "ltr",
        active: false,
      }
    },
    langHindi: "हिन्दी",
    langEnglish: "English",
    langNative: "हिन्दी",
  },
  // ... Add other language options
];


function Language() {

  const changeLanguage = (code) => {
    console.log(`language change working`);
    i18next.changeLanguage(code);
  };

  return (
    <div
      className="offcanvas offcanvas-end selectLanguage"
      tabIndex={-1}
      id="exampleModal"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Select Language</h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        {language.map((option, i) => {
          return (
            <React.Fragment key={i}>
              <p className="mb-1">{option.countryName}</p>
              <div className="lang-selection-wrapper d-flex align-items-center mb-3">
                <img
                  src={option.src}
                  alt={`${option.countryName} Flag`}
                  className="flag"
                />
                <a href="/en-BD" target="_self">
                  <div className="lang-bg-wrapper lang-bg-wrapper-border ps-3 pe-3 transform-capitalize">
                    <span className="ps-2">{option.langEnglish}</span>
                  </div>
                </a>
                <a href="/en-BD" target="_self">
                  <div className="lang-bg-wrapper lang-bg-wrapper-border ps-3 pe-3 transform-capitalize">
                    <span className="ps-2">{option.langHindi}</span>
                  </div>
                </a>
                <a href="/bn-BD" target="_self">
                  <div className="lang-bg-wrapper ps-3">
                    <span className="">{option.langNative}</span>
                  </div>
                </a>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="offcanvas-body">
        {language.map((option, i) => (
          <div
            key={i}
            className="lang-selection-wrapper d-flex align-items-center mb-3"

            style={{ cursor: "pointer" }}
          >
            <img
              src={option.src}
              alt={`${option.countryName} Flag`}
              className="flag"
              onClick={() => changeLanguage(option.langCode)}
            />
            <div className="lang-bg-wrapper lang-bg-wrapper-border ps-3 pe-3 transform-capitalize">
              <span className="ps-2">{option.langEnglish}</span>
            </div>
            <div className="lang-bg-wrapper lang-bg-wrapper-border ps-3 pe-3 transform-capitalize">
              <span className="ps-2">{option.langHindi}</span>
            </div>
            <div className="lang-bg-wrapper ps-3">
              <span className="">{option.langNative}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Language;
