import React from "react";
import { Link } from "react-router-dom";

// const about = [
//   "Privacy Policy",
//   "Terms & Conditions",
// ];

const About = () => {
  return (
    <div className="col-md-4">
      <h2 className="footer-title position-relative mb-4 d-none d-md-block" style={{visibility: 'hidden'}}>About </h2>
      {/* <ul className="footer-list p-0 mb-0">
        {about.map((data, i) => {
          return (
            <li key={i}>
              <a href="/">{data}</a>
            </li>
          );
        })}
      </ul> */}
      <ul className="footer-list p-0 mb-0">
            <li>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/termsandconditions">Terms & Conditions</Link>
            </li>
      </ul>
    </div>
  );
};

export default About;
