import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isLoggedIn } from "../../redux/slices/isLoggedIn.slice";
import { profilePicture } from "../../redux/slices/userCurrentPFP.slice";
import { setSocket } from "../../redux/slices/socket.slice";

const menu = [
  "Affiliates",
  "FAQ",
  "Privacy Policy",
  "Terms and Condition",
  "Disconnection Policy",
  "Responsible Gambling",
];

function SideMenu() {

  const dispatch = useDispatch()

  const isLogin = useSelector(state => state.login.value);
  const imageURL = useSelector(state => state.pfp.value?.avatar);
  const userName = useSelector(state => state.userName.value);
  const wallet = useSelector(state => state.wallet.value);
  const socket = useSelector(state => state.socket.value)

  const [nickName, setNickName] = useState('')

  useEffect(() => {
    if (isLogin) {
      setNickName(JSON.parse(localStorage.getItem('nickName')))
    }
  }, [isLogin])


  const logout = () => {
    const player_id = JSON.parse(localStorage.getItem('playerId'));
    const operator_id = JSON.parse(localStorage.getItem('oprId'));
    const token = JSON.parse(localStorage.getItem('authToken'))
    const data = { player_id, operator_id, token }
    socket.emit('logout', data)
    dispatch(setSocket(null))
    localStorage.clear()
    dispatch(isLoggedIn())
    dispatch(profilePicture(''))

  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvasMenu-block loginMenuoffcanvas"
      tabIndex={-1}
      id="offcanvasMenu"
      aria-labelledby="offcanvasRightLabel"
      style={{ zIndex: '10000' }}
    >
      <div className="offcanvas-header">
        <div className="user-offcanvas-details d-flex align-items-center" style={{ borderRadius: ' 3px' }}>
          <div className="user-icon me-2">
            <img src={`${imageURL || "assets/img/user.png"}`} alt="" />
          </div>
          <div className="userName">
            <p className="m-0 text-white fw-bold">{isLogin ? userName || nickName : "please login"}<span className="user-blancer d-block text-white fw-normal font-size-14">{isLogin ? wallet : '00'}</span></p>
          </div>
        </div>
        {/* <button className="addcash-btn"><i>₹</i> Add Cash</button> */}
        <button type="button" className="btn-close text-reset text-white" data-bs-dismiss="offcanvas" aria-label="Close">
          {/* <i className="fa-solid fa-xmark"></i> */}
        </button>
      </div>
      <div className="offcanvas-body px-0">
        <ul className="navbar-nav dropdown-menu-custome header-navbar-top d-flex align-items-center justify-content-between w-100">
          {/* <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-gift" /> My Wallet
            </a>
          </li>
          <li>
            <Link className="dropdown-item" to="/addCash" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-money-bill-transfer" />
              Deposit
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/withdrawal" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-money-bill-transfer" />
              Withdraw{" "}
            </Link>
          </li> */}
          <li className="dropdown-item">
            {isLogin && isLogin !== 'otp' && <Link className="dropdown-item" to="/info" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-address-card"></i> My Profile
            </Link>}
          </li>
          <li className="dropdown-item">
            {isLogin && isLogin !== 'otp' && <Link className="dropdown-item" to="/kyc" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-user" /> Kyc
            </Link>}
          </li>
          {/* {isLogin && isLogin !== 'otp' && <li>
            <Link className="dropdown-item" to="/game-history" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-clock-rotate-left" /> Games History
            </Link>
          </li>} */}
          <li>
            <Link className="dropdown-item" to="/history" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-clock-rotate-left" /> Transactions
            </Link>
          </li>
          {/* <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-gift" /> Coupons & Offers
            </a>
          </li>
          <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-ticket"></i> Game Tickets
            </a>
          </li>
          <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-gift" /> Referrals
            </a>
          </li>
          <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-gift" /> Taxes
            </a>
          </li> */}

          {/* <li className="dropdown-item">
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-gift" /> Bonus
            </a>
          </li>

          <li>
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-file-invoice" /> Bank Account
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-envelope-open-text" /> My Invitation
              potrtate
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-clock-rotate-left" /> Deposit Histoy
            </a>
          </li> */}
          {/* {isLogin === true && <li className="nav-item border-0 d-flex justify-content-center" style={{ padding: '10px 22px' }}>
            <Link className="downloadbtn" to="/" onClick={logout} data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-right-from-bracket" />Log out
            </Link>
          </li>} */}
        </ul>
      </div>
      <div className="offcanvas-footer">
        {isLogin === true &&
          <Link className="downloadbtn logOutBtn" to="/" onClick={logout} data-bs-dismiss="offcanvas">
            <i className="fa-solid fa-right-from-bracket" />Log out
          </Link>}
      </div>
    </div>

  );
}

export default SideMenu;
