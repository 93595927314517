import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const banner = [
  "assets/img/banner.jpg",
  "assets/img/slide1.jpg",
  // "assets/img/slide2.jpg",
  "assets/img/slide3.jpg",
  "assets/img/slide6.png",
  "assets/img/slide7.png"
]

const AdsBanner = () => {
  const owlCarouselOptions = {
    items: 1,
    margin: 0,
    nav: false,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    loop:true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className='w-100 bannerSlide'>
        <OwlCarousel className='owl-theme position-relative' {...owlCarouselOptions}>

          <div className="item">
            <img src="assets/img/banner.webp" className="w-100 mt-0 mb-0" alt="" />
          </div>
          <div className="item">
            <img src="assets/img/slide1.webp" className="w-100 mt-0 mb-0 mobile-position-img" alt="" />
          </div>
          

        </OwlCarousel>
      </div>




    </>
  )
}

export default AdsBanner