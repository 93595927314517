import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apis } from '../Services/apis'
import isLoggedIn from '../slices/isLoggedIn.slice'
import profilePicture from '../slices/userCurrentPFP.slice'
import wallet from '../slices/walletBalance.slice'
import userName from '../slices/userName.slice'
import lastRefreshed from '../slices/lastRefreshed.slice'
import setSocket from '../slices/socket.slice'

export const store = configureStore({
  reducer: {
    [apis.reducerPath]: apis.reducer,
    login: isLoggedIn,
    pfp: profilePicture,
    wallet: wallet,
    userName: userName,
    lastRefreshed: lastRefreshed,
    socket: setSocket
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apis.middleware),
})

setupListeners(store.dispatch)