import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const isLogin = createSlice({
    name: 'isLogin',
    initialState,
    reducers: {
        isLoggedIn: (state, action) => {
            const payload = action.payload
            if (!payload) {
                const token = localStorage.getItem('token')
                token ? state.value = true : state.value = false
            } else {
                state.value = payload
            }
        }
    },
})


export const { isLoggedIn } = isLogin.actions
export default isLogin.reducer