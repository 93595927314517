import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import { setSocket } from '../redux/slices/socket.slice';

export default function useSocketConnection() {

    const isLogin = useSelector(state => state.login.value);
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (!isLogin) return;

        const player_id = JSON.parse(localStorage.getItem('playerId'));
        const operator_id = JSON.parse(localStorage.getItem('oprId'));
        const token = JSON.parse(localStorage.getItem('authToken'));
        const data = { player_id, operator_id, token };

        const socket = io(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_SOCKET_PORT}`, {
            path: "/socket.io",
            transports: ["websocket"],
            secure: true
        })

        socket.emit('sendPlayerData', data);

        dispatch(setSocket(socket));

    }, [isLogin]);

};
