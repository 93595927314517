import { React, useEffect, useState } from "react";
import { useDummyGamesMutation, useGameListMutation } from "../../redux/Services/apis";
import { useSelector, useDispatch } from "react-redux";
import { isLoggedIn } from '../../redux/slices/isLoggedIn.slice'
import { Bounce, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { currentWalletBalance } from "../../redux/slices/walletBalance.slice";

const gamesNav = [
  { name: "ALL", img: "assets/img/all.png" },
  { name: "OTHERS", img: "assets/img/roulette.png" },
  { name: "TABLE", img: "assets/img/table.png" },
  { name: "DRAW", img: "assets/img/draw.png" },
  { name: "ROULETTE", img: "assets/img/roulette.png" },
  { name: "SLOT", img: "assets/img/slot.png" },
];
const gamesNavMb = [
  { name: "ALL", img: "assets/img/select-all.png" },
  { name: "OTHERS", img: "assets/img/menu.png" },
  { name: "TABLE", img: "assets/img/billiard.png" },
  { name: "DRAW", img: "assets/img/chess-game.png" },
  { name: "ROULETTE", img: "assets/img/billiard.png" },
  { name: "SLOT", img: "assets/img/jackpot.png" },
];

const getGameURL = (data) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'));
  const oprId = JSON.parse(localStorage.getItem('oprId'));
  const gameCode = data.game_code;
  const ip = data.integration_env_config.url_path;
  const url = `http://${ip}/?token=${authToken}&game_code=${gameCode}&opr_id=${oprId}&href=${window.location.href}`;
  // const url = `http://localhost:3001/?token=${authToken}&game_code=${gameCode}&opr_id=${oprId}&href=${window.location.href}`;
  if (!ip) {
    toast.warn('Game is currently not available!', {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    return;
  }
  window.location.href = url;
}

const Games = () => {
  const [gameList, { data, isSuccess, isLoading, isError, error, reset }] = useGameListMutation()
  const [dummyGames, { data: dummyData, isSuccess: dummySuccess, isLoading: dummyLoading }] = useDummyGamesMutation()
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.login.value)

  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const oprId = JSON.parse(localStorage.getItem('oprId'))
      const playerId = JSON.parse(localStorage.getItem('playerId'))
      const token = JSON.parse(localStorage.getItem('token'))
      if (isLogin !== 'otp') {
        dispatch(isLoggedIn())
      }
      if (isLogin && isLogin !== 'otp') {
        await gameList({ oprId, playerId, token })
      } else if (isError) {
        console.log(error);
      }
      else {
        reset(data)
      }
    })()
  }, [isLogin])

  useEffect(() => {
    if (data && data.message === 'Token mismatch') {
      localStorage.clear()
      dispatch(isLoggedIn())
    } else if (data && data.message === 'Player Does not exist for this operator') {
      localStorage.clear()
      dispatch(isLoggedIn())
    } else if (data && data.status) {
      dispatch(currentWalletBalance(data.wallet_balance))
    }
  }, [data])

  useEffect(() => {
    const oprId = process.env.REACT_APP_OPERATOR_ID;
    !isLogin && dummyGames({ oprId })
      .then(e => console.log(e.data.data))
      .catch(e => console.log(e))
  }, [isLogin])

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  console.log('dataaaaa', data?.data);
  const query = useLocation().search.split('?')[1]


  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(query);
      const queryParams = {};
      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }
      if (Object.keys(queryParams).includes('game_code')) {
        if (query && data && !isLoading && isSuccess && isLogin) {
          setLoad(true)
          if (isLogin && isLogin !== 'otp') {
            console.log('dataaaa', data);
            const findIndex = data?.data?.findIndex(i => i.game_code === queryParams.game_code)
            if (findIndex > -1) {
              if (data?.data[findIndex].operational_status === 'live') {
                console.log('findIndex', findIndex);
                const gameData = data.data[findIndex]
                getGameURL(gameData);
                setTimeout(() => {
                  setLoad(false)
                }, 2000);
              } else {
                setLoad(false)
                setMessage('Game is not live yet!')
                alert('Game is not live yet!')
              }
            } else {
              setLoad(false)
              setMessage('Game code is invalid!')
              alert('Game code is invalid!');
              navigate('/')
            }
          }
        } else {
          // props.setOpenLoginModal(true)
        }
      } else if (Object.keys(queryParams).includes('aff_code')) {
        console.log("🚀 ~ queryParams:", queryParams, isLogin)
        const aff_code = queryParams.aff_code
        sessionStorage.setItem('aff_code', aff_code)
        navigate('/signup')
      }
      // setLoad(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, isLoading, isLogin])

  return (
    <>
      {load ?
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-danger" role="status" style={{ height: '70px', width: '70px' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        : !isError && <div className="container-fluid gameTabs">
          <div className="row">
            <div
              className="col-12 tabsNav position-relative"
              style={{ background: "#141a1f" }}
            >
              <ul
                className="nav nav-tabs w-100 border-0"
                id="myTab"
                role="tablist"
              >
                {gamesNav.map((option, i) => {
                  return (
                    <li className="nav-item d-none d-md-block" role="presentation" key={i}>
                      <a
                        key={i}
                        className={`nav-link ${i === 0 ? "active" : ""} `}
                        id={`${option.name.toLocaleLowerCase()}-tab`}
                        data-bs-toggle="tab"
                        href={`#${option.name.toLocaleLowerCase()}`}
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <img src={option.img} alt="" /> {option.name}
                      </a>
                    </li>
                  );
                })}
                {gamesNavMb.map((option, i) => {
                  return (
                    <li className="nav-item d-block d-md-none" role="presentation" key={i}>
                      <a
                        key={i}
                        className={`nav-link ${activeTabIndex === i ? "active" : ""}`}
                        id={`${option.name.toLocaleLowerCase()}-tab`}
                        data-bs-toggle="tab"
                        href={`#${option.name.toLocaleLowerCase()}`}
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected={i === 0 ? "true" : "false"}
                        onClick={() => handleTabClick(i)}
                      >
                        <img src={option.img} /> {option.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="row mt-2 mt-md-4">
            <div className="tab-content col-12 mt-2 px-4" id="myTabContent">
              {gamesNav.map((option, i) => {
                return (<div key={i} className={`tab-pane fade ${i === 0 ? "active show" : ""}`} id={option.name.toLocaleLowerCase()} role="tabpanel" aria-labelledby="Roulette-tab"              >
                  <div className="row gameplay-row justify-content-center">
                    {!isLoading && isSuccess && isLogin && isLogin !== 'otp' && data.data?.filter(option => option.operational_status === 'live').map((option, i) => {
                      return (
                        <div className="col-md-2 col-6 mb-2 gamesDiv" key={i} onClick={() => getGameURL(option)}>
                          <div className="w-75 mb-4 game-icon position-relative">
                            <a href={option.href} className="w-100">
                              <img src={option.game_icon} className="w-100 gamesImage" alt={option.game_name} />
                              <div className="playgame-btn"><button>Play</button></div>
                            </a>
                            <a
                              href={option.href}
                              className="d-md-none playnow-mb position-absolute bottom-0"
                            >
                              {option.gameName}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    {!dummyLoading && dummySuccess && (!isLogin || isLogin === 'otp') && dummyData.data?.filter(option => option.operational_status === 'live')?.map((option, i) => {
                      return (
                        <div className="col-md-2 col-6 mb-2 gamesDiv" data-bs-toggle="modal" data-bs-target="#loginModal" key={i} >
                          <div className="w-75 mb-4 game-icon position-relative">
                            <a className="w-100">
                              <img src={option.game_icon} className="w-100 gamesImage" alt={option.game_name} />
                              <div className="playgame-btn"><button>Play</button></div>
                            </a>
                            <a
                              // href={option.href}
                              className="d-md-none playnow-mb position-absolute bottom-0"
                            >
                              {option.gameName}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    {/* {!isLoading && isSuccess && data.data?.filter(option => option.operational_status === 'offline').map((option, i) => {
                      return (
                        <div className="col-md-2 col-6 mb-2 gamesDiv upcoming-block" key={i} >
                          <div className="w-75 mb-4 game-icon position-relative">
                            <a href={option.href} className="w-100">
                              <img src={option.game_icon} className="w-100 gamesImage" alt={option.game_name} />
                              <div className="upcoming-text">upcoming</div>
                            </a>
                            <a
                              href={option.href}
                              className="d-md-none playnow-mb position-absolute bottom-0"
                            >
                              {option.gameName}
                            </a>
                          </div>
                        </div>
                      );
                    })} */}

                    {/* {!dummyLoading && dummySuccess && (!isLogin || isLogin === 'otp') && dummyData.data?.filter(option => option.operational_status === 'offline').map((option, i) => {
                      return (
                        <div className="col-md-2 col-6 mb-2 gamesDiv upcoming-block" key={i} >
                          <div className="w-75 mb-4 game-icon position-relative">
                            <a href={option.href} className="w-100">
                              <img src={option.game_icon} className="w-100 gamesImage" alt={option.game_name} />
                              <div className="upcoming-text">upcoming</div>
                            </a>
                            <a
                              href={option.href}
                              className="d-md-none playnow-mb position-absolute bottom-0"
                            >
                              {option.gameName}
                            </a>
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                </div>
                );
              })}
            </div>
          </div>
        </div>}
    </>
  );
};

export default Games;
