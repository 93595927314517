import React from "react";
import { Link } from "react-router-dom";
import MainHeader from "./mainHeader";
import PrimaryNavigation from "../navigation/navigation";
import Footer from "../footer/footer";
import { useSelector } from "react-redux";

export default function GamesHistory() {
    const isLogin = useSelector(state => state.login.value);
    return (
        <>
        <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-md-3 mb-3">
                <div className="container">
                    <div className="row">
                    <div className="col-12 p-0">
                                <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                    <p className="box-title text-white">Game History</p>
                                    <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                <i className="fa-solid fa-xmark"></i>
                            </Link>
                                </header>
                            </div>
                        <div className="box-block position-relative">
                            
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-50">
                                        <select
                                            className="form-select transactions-select"
                                            aria-label="Default select example"
                                        >
                                            <option>Choose Game</option>
                                            <option>Poker</option>
                                            <option>Rummy</option>
                                        </select>
                                    </div>
                                    <div className="w-100 mt-3">
                                        <div className='w-100 overflow-auto'>
                                        <table className="table table-bordered w-100">
                                            <thead>
                                                <tr>
                                                    <th>Date </th>
                                                    <th>Time</th>
                                                    <th>Game</th>
                                                    <th>Opening Balance</th>
                                                    <th>Amount</th>
                                                    <th>Closing Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}