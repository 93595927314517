import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from "../components/header/mainHeader";
import PrimaryNavigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";
import { Bounce, toast } from "react-toastify";
import { useKycMutation } from "../redux/Services/apis";

export default function Kyc() {
    const DOC_TYPE = Object.freeze({
        AADHAR: 'aadhar',
        PAN: 'pan'
    })

    const [kyc, { data, isSuccess }] = useKycMutation()

    const [selectedDocumentType, setSelectedDocumentType] = useState('AADHAR Card');
    const [frontImage, setFrontImage] = useState('')
    const [backImage, setBackImage] = useState('')
    const [docNum, setDocNum] = useState('')
    const [kycType, setKycType] = useState(DOC_TYPE.AADHAR)
    const [docName, setDocName] = useState('')
    const [imageSrc, setImageSrc] = useState('');
    const [showUploadBoxInner, setShowUploadBoxInner] = useState(true);
    const [imageSrcBack, setimageSrcBack] = useState('');
    const [showUploadBoxBack, setShowUploadBoxBack] = useState(true);

    const handleDocumentTypeChange = (event) => {
        setSelectedDocumentType(event.target.value);
        console.log(event.target.value);
        if (event.target.value === 'AADHAR Card') {
            setKycType(DOC_TYPE.AADHAR)
        } else if (event.target.value === 'Pan Card') {
            setKycType(DOC_TYPE.PAN)
        }
        setDocNum('')
        setDocName('')
        setFrontImage('')
        setBackImage('')
    };

    const convertFrontImageToBase64 = (e) => {
        const reader = new FileReader();
        console.log(e);
        if (e.target.files.length === 0) {
            return;
        }
        reader.readAsDataURL(e.target?.files?.[0])
        const name = e.target.files[0].name
        // setImageName(name)
        reader.onload = () => {
            const img = reader.result.split(',')[1];
            console.log(img);
            setFrontImage(img);
        }

        const file = e.target.files[0];
        if(file) {
            const imagUrl = URL.createObjectURL(file);
            setShowUploadBoxInner(false);
            setImageSrc(imagUrl);
        }
    }

    const convertBackImageToBase64 = (e) => {
        const reader = new FileReader();
        console.log(e);
        if (e.target.files.length === 0) {
            return;
        }
        reader.readAsDataURL(e.target?.files?.[0])
        const name = e.target.files[0].name
        // setImageName(name)
        reader.onload = () => {
            const img = reader.result.split(',')[1];
            setBackImage(img);
        }
        const fileBack = e.target.files[0];
        if(fileBack) {
            const imagBackUrl = URL.createObjectURL(fileBack);
            setShowUploadBoxBack(false);
            setimageSrcBack(imagBackUrl);
        }
    }

    const handleNumChange = (e) => {
        console.log(kycType);
        const val = e.target.value.toUpperCase()
        if (kycType === DOC_TYPE.AADHAR && e.target.value.length <= 12) {
            setDocNum(val)
        } else if (kycType === DOC_TYPE.PAN && val.length <= 10) {
            setDocNum(val)
        }
    }

    const uploadDocs = () => {
        const aadharRegex = /^\d{12}$/;
        const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
        const playerId = JSON.parse(localStorage.getItem('playerId'))
        const oprId = JSON.parse(localStorage.getItem('oprId'))
        if (!playerId || !oprId || !frontImage || !docNum || !docName) {
            !frontImage && toast.error('Please attach document images', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
        }
        if (kycType === DOC_TYPE.AADHAR && !aadharRegex.test(docNum)) {
            toast.error('Invalid document details', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        } else if (kycType === DOC_TYPE.PAN && !panRegex.test(docNum)) {
            toast.error('Invalid document details', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }
        kyc({ oprId, playerId, kycType, docName, frontImage, backImage, docNum })
    }

    useEffect(() => {
        console.log(isSuccess, data);
        if (isSuccess && data.message === 'already kyc details submitted') {
            toast.warn('You already submitted KYC details', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (isSuccess && (data.message === 'aadhar details updated' || data.message === 'pan details updated')) {
            toast.success('Document updated successfully.', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (isSuccess && data.message === ',back_img is empty') {
            toast.error('Please attach document images', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return
        }
        setBackImage('')
        setFrontImage('')
        setDocName('')
        setDocNum('')
    }, [isSuccess, data])
    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="wrapper pt-0">
                <div className="container-fluid pt-1 pt-md-3 mb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                    <p className="box-title text-white">KYC Information</p>
                                    <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                        <i className="fa-solid fa-xmark"></i>
                                    </Link>
                                </header>
                            </div>
                            <div className="col-md-6 mb-md-0 mb-0 pe-md-0">
                                <div className="box-block h-100 position-relative borderradius-start border_radius_mb_none">
                                    <h3 className="box-title mb-2 mb-md-3">KYC Details </h3>
                                    <div className="mb-2 mb-md-3">
                                        <label className="form-label">Select Type</label>
                                        <select className="form-select" onChange={handleDocumentTypeChange} value={selectedDocumentType} aria-label="Default select example"
                                        >
                                            <option selected="" value="AADHAR Card">AADHAR Card</option>
                                            {/* <option value="Driving Licence">Driving Licence</option> */}
                                            <option value="Pan Card" >Pan Card</option>
                                        </select>
                                    </div>
                                    <div className="mb-2 mb-md-3">
                                        <label className="form-label">Enter Document Number</label>
                                        <input className="form-control" type="number" value={docNum} onChange={handleNumChange} />
                                    </div>
                                    <div className="mb-2 mb-md-3">
                                        <label className="form-label">Your Name As Per Proof</label>
                                        <input className="form-control" type="text" value={docName} onChange={(e) => setDocName(e.target.value)} />
                                    </div>
                                    <div className="mb-2 mb-md-3">
                                        <label className="form-label">Email</label>
                                        <div className="d-flex email-block-btn position-relative">
                                            <input className="form-control" type="text" name="" />
                                            {/* <button className="allBtn confirmEmailBtn ms-0 ms-md-3 mt-3 mt-md-0 inputemailbtn-inner">
                                                Confirm User
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-md-0 mb-0 ps-md-0">
                                <div className="box-block h-100 position-relative borderradius-end border_radius_mb_bottom">
                                    <h3 className="box-title mb-md-5 mb-3">Upload Image</h3>
                                    <div className="d-flex flex-wrap document-up-block">
                                        <div className="uploadBox position-relative">
                                            <input type="file" accept='image/*' onChange={convertFrontImageToBase64} style={{ fontSize: '0', opacity: '0', cursor: "pointer" }} className="w-100 h-100 position-absolute border-0 bg-transparent start-0 end-0 top-0 bottom-0" />
                                            {showUploadBoxInner && <div className="uploadbox-inner">
                                                Document Front
                                            </div>}
                                            {imageSrc &&
                                            <img className="uploadImage-inner" src={imageSrc} />
                                            }
                                        </div>
                                        {selectedDocumentType !== 'Pan Card' && (
                                            <div className="uploadBox position-relative mt-3 mt-md-0 ms-md-4">
                                                <input type="file" accept='image/*' onChange={convertBackImageToBase64} style={{ fontSize: '0', opacity: '0', cursor: "pointer" }} className="w-100 h-100 position-absolute border-0 bg-transparent start-0 end-0 top-0 bottom-0" />
                                               {showUploadBoxBack && <div className="uploadbox-inner">
                                                    Document Back
                                                </div> }
                                                {imageSrcBack && <img className="uploadImage-inner" src={imageSrcBack} />}
                                            </div>
                                        )}
                                        <div className="w-100 text-center mt-2 mt-md-4">
                                            <button onClick={uploadDocs} className="allBtn confirmEmailBtn ms-0 ms-md-0 mt-3 mt-md-0 ms-0">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}