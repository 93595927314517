import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    value: ''
}

export const userName = createSlice({
    name: 'userName',
    initialState,
    reducers: {
        uName: (state, action) => {
            const payload = action.payload
            state.value = payload
        }
    }
})

export const { uName } = userName.actions
export default userName.reducer