/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from 'react-router-dom'
import useCheckLogin from '../Middleware/useCheckLogin'
import AdsBanner from '../components/adsBanner/adsBanner'
import Footer from '../components/footer/footer'
import Games from '../components/games/games'
import MainHeader from '../components/header/mainHeader'
import PrimaryNavigation from '../components/navigation/navigation'
import { useEffect } from 'react'
import useSocketConnection from '../Middleware/useSocketConnection'
import { useSelector } from 'react-redux'

const Dashboard = () => {

  const endpoint = useLocation().pathname
  const navigate = useNavigate()
  const socket = useSelector(state => state.socket.value)

  useCheckLogin('/')
  useSocketConnection()

  useEffect(() => {
    endpoint !== '/' && navigate('/')
  }, [])

  useEffect(() => {
    if (!socket) return;

    socket.on('deposit_failed', (data) => {
      console.log('sockettt', data);
    })

    socket.on('deposit_confirm', (data) => {
      console.log('sockettt', data);
    })

    socket.on('withdraw_failed', (data) => {
      console.log('sockettt', data);
    })

    socket.on('withdraw_confirm', (data) => {
      console.log('sockettt', data);
    })

    socket.on('info', (data) => {
      console.log('sockettt', data);
    })

    socket.on('leaveSocket', (data) => {
      console.log('sockettt', data);
    })

    socket.on('oms_notifications', (data) => {
      console.log('sockettt', data);
    })

    return () => {
      socket.off('deposit_failed')
      socket.off('deposit_confirm')
      socket.off('withdraw_failed')
      socket.off('withdraw_confirm')
      socket.off('info')
      socket.off('leaveSocket')
      socket.off('oms_notifications')
    }
  }, [socket])

  return (
    <>
      <MainHeader />
      <PrimaryNavigation />
      <AdsBanner />
      <Games />
      <Footer />
    </>
  )
}

export default Dashboard