import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/slices/isLoggedIn.slice";
import { useEmailConfirmMutation, useEmailLoginMutation, useMobileConfirmMutation, useMobileLoginMutation } from "../../redux/Services/apis";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uName } from "../../redux/slices/userName.slice";

const LoginButton = () => {
  const [mobileLogin, { data: mobileData, error: loginError }] = useMobileLoginMutation();
  const [mobileConfirm, { error: confirmError }] = useMobileConfirmMutation();
  const [emailLogin, { data: emailData }] = useEmailLoginMutation();
  const [emailConfirm] = useEmailConfirmMutation();

  const isLogin = useSelector(state => state.login.value);

  const navigate = useNavigate();
  const query = useLocation().search.split('?')[1] || '';

  const [error, setError] = useState("");
  const [phoneNo, setPhoneNo] = useState('');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('')
  const [legitEmail, setLegitEmail] = useState(false)
  const [loginWith, setLoginWith] = useState('mobile')
  const [preloader, setPreloader] = useState(false);

  const regex = /^[0-9]{10}$/;

  const dispatch = useDispatch();
  useState(() => {
    dispatch(isLoggedIn());
  }, [])

  useEffect(() => {
    (() => {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      setLegitEmail(regex.test(email))
    })()
  }, [email])

  const login = () => {
    const oprId = process.env.REACT_APP_OPERATOR_ID;
    const regex = /^[0-9]{10}$/;
    if (!regex.test(phoneNo)) {
      setError('Enter valid Phone no and operator')
      setPhoneNo('')
      return
    }
    mobileLogin({ phoneNo, oprId })
      .then(res =>
        localStorage.setItem("operatorInfo", JSON.stringify(res.data)),
        dispatch(isLoggedIn("otp")),
        setError(''),
        setPhoneNo('')
      )
      .catch(error =>
      (setPhoneNo(''),
        setError(loginError))
      )
    setPreloader(true)
  };

  const submitOTP = () => {
    console.log(otp);
    // const regex = /^[0-9]$/;
    // if (!regex.test(Number(otp))) {
    //   setError('Enter valid OTP')
    //   toast.error('Please enter valid OTP', {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   })
    //   // setOtp(123456)
    //   return
    // }
    const operatorDetails = JSON.parse(localStorage.getItem('operatorInfo'))

    const body = {
      "otp": Number(otp),
      "_id": operatorDetails?.objectId,
      "mobile_number": operatorDetails?.mobile_number,
      "operator_id": operatorDetails?.operator_id,

    }

    const expirationDate = new Date();
    mobileConfirm(body)
      .then(
        res => {
          console.log(res.data);
          if (res.data.message === "Otp Mismatch") {
            localStorage.clear()
            toast.error('Entered wrong OTP', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
            // setOtp('')
            return
          }
          else if (res.data.status === 1) {
            expirationDate.setDate(expirationDate.getDate() + 180)
            localStorage.setItem('token', JSON.stringify(res.data.token))
            localStorage.setItem('authToken', JSON.stringify(res.data.authToken))
            localStorage.setItem('oprId', JSON.stringify(res.data.opr_code))
            localStorage.setItem('playerId', JSON.stringify(res.data.player_id))
            localStorage.setItem('nickName', JSON.stringify(res.data.display_name))
            localStorage.setItem('wallet_balance', JSON.stringify(res.data.wallet_balance))
            const name = res.data.display_name
            dispatch(uName(name))
            // setOtp('')
            setError('')
            dispatch(isLoggedIn())
            navigate(`/?${query}`)
          } else {
            localStorage.clear()
          }
        }
      )
      .catch(error =>
      (setError(confirmError)
        // setOtp('')
      )
      )
  }

  const loginEmail = () => {
    const oprId = process.env.REACT_APP_OPERATOR_ID;
    emailLogin({ email, oprId })
      .then(res => (console.log(res),
        localStorage.setItem("operatorInfo", JSON.stringify(res.data)),
        setEmail(''),
        setError('')
      ))
    setPreloader(true);
  }

  const confirmEmail = () => {
    const operatorDetails = JSON.parse(localStorage.getItem('operatorInfo'))
    const _id = operatorDetails.objectId;
    const email = operatorDetails.email;
    const operator_id = operatorDetails.operator_id;
    const otp = '123456';
    const body = { otp, _id, email, operator_id }
    const regex = /^(\d)?\d{6}$/;
    console.log(body);
    if (!regex.test(otp)) {
      alert('please enter valid otp')
      return
    }
    emailConfirm(body)
      .then(res => {
        console.log(res.data);
        if (res.data.message === "Invalid OTP") {
          localStorage.clear()
          toast.error('Invalid OTP', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          // setOtp('')
          return
        }
        localStorage.setItem('token', JSON.stringify(res.data.token))
        localStorage.setItem('authToken', JSON.stringify(res.data.authToken))
        localStorage.setItem('oprId', JSON.stringify(res.data.opr_code))
        localStorage.setItem('playerId', JSON.stringify(res.data.player_id))
        localStorage.setItem('nickName', JSON.stringify(res.data.display_name))
        localStorage.setItem('wallet_balance', JSON.stringify(res.data.wallet_balance))
        // setOtp('')
        setError('')
        dispatch(isLoggedIn())
        navigate(`/?${query}`)
      })
  }

  // useEffect(() => {
  //   console.log('mobileData', mobileData);
  //   if (mobileData?.message === "OTP SENT SUCCESSFULLY (New User)" || mobileData?.message === "OTP SENT SUCCESSFULLY (Existing User)") {
  //     submitOTP()
  //   }
  //   else if (emailData?.message === "OTP SENT SUCCESSFULLY (New User)" || emailData?.message === "OTP SENT SUCCESSFULLY (Existing User)") {
  //     confirmEmail()
  //   }
  // }, [mobileData, emailData])

  useEffect(() => {
    if (isLogin && isLogin !== 'otp') {
      setPreloader(false);
    }
  }, [isLogin])

  return (
    <>
      {/* Preloader */}

      {preloader &&
        <div id="preloader">
          <div id="preloader-status"></div></div>}

      {/* Preloader End! */}

      {(!isLogin || isLogin === 'otp') && (
        <>
          <div className="w-auto d-flex ps-0">
            {/* <button type="button" className="btn text-white btn-login m-0 me-1 me-md-3" data-bs-toggle="modal" data-bs-target="#emailLoginModal" style={{ background: 'var(--warning-base)' }}>
              Email Login
            </button> */}
            {/* <!-- Button trigger modal --> */}
            <button type="button" className="btn text-white btn-login m-0" data-bs-toggle="modal" data-bs-target="#loginModal" style={{ background: 'var(--warning-base)' }}>
              Login
            </button>

            {/* <!-- Login Modal --> */}
            <div className={`modal fade `} id="loginModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ overflow: 'hidden' }}>
              <div className="modal-dialog centerModal">
                <div className="modal-content bg-dark">
                  <div className="d-flex justify-content-center">
                    <div className="modal-header justify-content-center border-dark w-100" style={{ background: loginWith === 'mobile' && '#414950', cursor: 'pointer' }} onClick={() => setLoginWith('mobile')}>
                      <h1 className="modal-title fs-5 text-white user-select-none" id="staticBackdropLabel"> Mobile Login </h1>
                    </div>
                    {/* <div className="modal-header justify-content-center border-dark w-50" style={{ background: loginWith === 'email' && '#414950', cursor: 'pointer' }} onClick={() => setLoginWith('email')}>
                      <h1 className="modal-title fs-5 text-white user-select-none" id="staticBackdropLabel"> Email Login</h1>
                    </div> */}
                  </div>
                  <div className="modal-body ">
                    <div className="mb-3 d-flex flex-column justify-content-center" style={{ alignItems: 'center' }}>
                      {loginWith === 'mobile' ? <label htmlFor="mobileNumber" className="form-label text-white" style={{ marginBottom: '0.5rem', display: 'inline-block' }}>Mobile Number</label>
                        :
                        <label htmlFor="email" className="form-label text-white" style={{ marginBottom: '0.5rem', display: 'inline-block' }}>Email</label>}
                      <div className="w-100 postion-relative login-numInput">
                        {loginWith === 'mobile' && <span className="country-num-code">+91</span>}
                        {loginWith === 'mobile' ? <input type="tel" className="form-control rounded-pill" style={{ 'background': '#512d25', 'paddingLeft': '10px' }} value={phoneNo} autoComplete="off" onChange={(e) => { e.target.value?.length < 11 && setPhoneNo(e.target.value) }} id="mobileNumber" aria-describedby="loginModal" />

                          : <input type="email" className="form-control rounded-pill" style={{ 'background': '#512d25', 'paddingLeft': '10px' }} value={email} autoComplete="off" onChange={(e) => { setEmail(e.target.value) }} id="email" aria-describedby="loginModal" />}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-center border-dark">

                    {loginWith === 'mobile' && !regex.test(phoneNo) && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#confirmModal" disabled onClick={login}>
                      send otp
                    </button>}
                    {loginWith === 'mobile' && regex.test(phoneNo) && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#confirmModal" onClick={login}>
                      {/* {loginWith === 'mobile' && regex.test(phoneNo) && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" onClick={login}> */}
                      send otp
                    </button>}

                    {loginWith !== 'mobile' && !legitEmail && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#confirmModal" disabled >
                      Confirm
                    </button>}
                    {/* {loginWith !== 'mobile' && legitEmail && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#emailConfirmModal" onClick={loginEmail}> */}
                    {loginWith !== 'mobile' && legitEmail && <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" onClick={loginEmail}>
                      Confirm
                    </button>}

                  </div>
                </div>
              </div>
            </div>

            {/* <!-- OTP Modal --> */}
            <div className={`modal fade `} id="confirmModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ overflow: 'hidden' }}>
              <div className="modal-dialog centerModal">
                <div className="modal-content bg-dark">
                  <div className="modal-header justify-content-center border-dark">
                    <h1 className="modal-title fs-5 text-white" id="staticBackdropLabel">Enter OTP</h1>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3 d-flex flex-column justify-content-center" style={{ alignItems: 'center' }}>
                      <input type="email" className="form-control rounded-pill" style={{ 'background': '#512d25', 'paddingLeft': '10px' }} autoComplete="off" onChange={(e) => { e.target.value?.length < 7 && setOtp(e.target.value) }} id="mobileNumber" aria-describedby="emailHelp" value={otp} />
                    </div>
                  </div>
                  {otp?.length < 5 && <div className="modal-footer d-flex justify-content-center border-dark">
                    <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" disabled onClick={() => (submitOTP())}>Submit OTP</button>
                  </div>}
                  {otp?.length >= 5 && <div className="modal-footer d-flex justify-content-center border-dark">
                    <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" onClick={() => (submitOTP())}>Submit OTP</button>
                  </div>}
                </div>
              </div>
            </div>

            {/* <!-- Email OTP Modal --> */}
            <div className={`modal fade `} id="emailConfirmModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ overflow: 'hidden' }}>
              <div className="modal-dialog centerModal">
                <div className="modal-content bg-dark">
                  <div className="modal-header justify-content-center border-dark">
                    <h1 className="modal-title fs-5 text-white" id="staticBackdropLabel">Enter OTP</h1>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3 d-flex flex-column justify-content-center" style={{ alignItems: 'center' }}>
                      <input type="email" className="form-control rounded-pill" style={{ 'background': '#512d25', 'paddingLeft': '10px' }} autoComplete="off" onChange={(e) => { e.target.value?.length < 7 && setOtp(e.target.value) }} id="mobileNumber" aria-describedby="emailHelp" value={otp} />
                    </div>
                  </div>
                  {otp?.length < 5 && <div className="modal-footer d-flex justify-content-center border-dark">
                    <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" disabled onClick={() => (submitOTP())}>Submit OTP</button>
                  </div>}
                  {otp?.length >= 5 && <div className="modal-footer d-flex justify-content-center border-dark">
                    <button type="button" className="btn text-white btn-login m-0 border-white rounded-pill" data-bs-dismiss="modal" onClick={() => (confirmEmail())}>Submit OTP</button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LoginButton;
