import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../redux/slices/isLoggedIn.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useRefreshTokenMutation } from "../redux/Services/apis";
import { toast } from "react-toastify";
import { whenLastRefreshed } from "../redux/slices/lastRefreshed.slice";

export default function useCheckLogin(currentPath) {
    const [refreshToken, { isSuccess, data }] = useRefreshTokenMutation();

    const isLogin = useSelector(state => state.login.value);
    const lastRefreshed = useSelector(state => state.lastRefreshed.value2)

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const path = useLocation().pathname;
    const query = useLocation().search.split('?')[1] || '';

    useEffect(() => {
        dispatch(whenLastRefreshed())
        if (!lastRefreshed) {
            return
        }
        if (path === currentPath && isLogin !== 'otp') {
            const playerId = JSON.parse(localStorage.getItem('playerId'));
            const oprId = JSON.parse(localStorage.getItem('oprId'));
            const token = JSON.parse(localStorage.getItem('authToken'));
            if (playerId && oprId && token) {
                refreshToken({ playerId, oprId, token })
                    .then(res => console.log(res));
            } else if (query.includes('aff_code')) {

            } else {
                localStorage.clear()
                dispatch(isLoggedIn(false));
                navigate(`/?${query}`)
            }
        }
    }, [])

    useEffect(() => {
        if (path === currentPath) {
            if (data?.message === 'invalid current auth token') {
                console.log('invalid current auth token');
                localStorage.clear()
                dispatch(isLoggedIn(false));
                navigate(`/?${query}`)
            } else if (data?.message === ',current_auth_token is not string') {
                console.log(',current_auth_token is not string');
                localStorage.clear()
                dispatch(isLoggedIn(false));
                navigate(`/?${query}`)
            } else if (data?.message === ',operator_id is empty,player_id is empty') {
                console.log(',operator_id is empty,player_id is empty');
                localStorage.clear()
                dispatch(isLoggedIn(false));
                navigate(`/?${query}`)
            } else if (data?.message === 'token updated successfully') {
                localStorage.setItem('authToken', JSON.stringify(data.token))
                console.log('updated');
                dispatch(isLoggedIn(true));
            } else if (data?.error?.error === 'TypeError: Failed to fetch') {
                localStorage.clear()
                dispatch(isLoggedIn(false));
                toast.error('internal server error', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        }
    }, [isSuccess])
}
