import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    value: 0
}

export const wallet = createSlice({
    name: 'walletBalance',
    initialState,
    reducers: {
        currentWalletBalance: (state, action) => {
            state.value = action.payload
        },
        increaseWalletBalance: (state, action) => {
            state.value += action.payload
        },
        decreaseWalletBalance: (state, action) => {
            state.value -= action.payload
        }
    }
})

export const {currentWalletBalance, increaseWalletBalance, decreaseWalletBalance} = wallet.actions
export default wallet.reducer