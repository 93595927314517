import React from "react";
import MainHeader from "../components/header/mainHeader";
import PrimaryNavigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";
import { Link } from "react-router-dom";

export default function Promotions() {
    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-3 mb-3">
                <div className="container">

                    <div className="row">
                    <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                <p className="box-title text-white">Promotions</p>
                                <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                    <i className="fa-solid fa-xmark"></i>
                                </Link>
                            </header>
                        <div className="box-block position-relative des-content-block">
                            <p> Enjoy the Rummy offers and benefits to make your gaming experience even more exciting and rewarding. Enjoy various offers, schemes, rewards and benefits on a regular basis only at Card Flix.com. Register today, start your play and win multiple rewards.</p>
                            <h3 className="sub-title-text"> Read up more about the ongoing bonus offers at Card Flix.com.</h3>
                            <p>   A player is entitled to 25 rs instant cash as sign up bonus and 100% welcome bonus on the first deposit made.</p>
                            <p>   A player is automatically entitled to the Card Flix's refer a friend bonus when he/she refer a friend (visit bring a friend t&c for more details). </p>
                            <p>   No bonus code needs to be entered to avail our bring a friend bonus.</p>
                            <p>   The bonus code is automatically applied as soon as your referred friend registers on the Card Flix through your referral link and makes his/her first deposit. in order to avail any other promotion or bonus, kindly enter the valid bonus code for that particular promotion or bonus offer. </p>
                            <p>   Kindly go through the terms & conditions for each promotion or bonus offer launched on the Card Flix, to know more about its validity, expiry and applicable t&c.</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}