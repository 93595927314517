import React from 'react'
import { useSelector } from 'react-redux';
import useCheckLogin from '../Middleware/useCheckLogin';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './footer/footer';
import MainHeader from './header/mainHeader';
import PrimaryNavigation from './navigation/navigation';

export default function Withdrawal() {

    const isLogin = useSelector(state => state.login.value);

    useCheckLogin('/withdrawal')

    const navigateBack = useNavigate();

    // const handleGoBack = () => {
    //     navigateBack(-1);
    // };

    return (
        <>
            <MainHeader />
            <PrimaryNavigation />
            <div className="container-fluid pt-1 pt-md-3 mb-3">
                {isLogin && isLogin !== 'otp' && <div className="container">
                    <div className="row">
                    <div className="col-12">
                                <header className="d-flex w-100 start-0 justify-content-center py-3 myProfile position-relative">
                                    <p className="box-title text-white">Withdraw <Link to='/withdraw-cash'>Withdraw page</Link></p>
                                    <Link to="/" type="button" className="btn-close text-reset text-white page-close">
                                <i className="fa-solid fa-xmark"></i>
                            </Link>
                                </header>
                            </div>
                        <div className="col-12">
                            <div className="box-block position-relative">
                                
                                <div className="row border rounded">
                                    <div className="col-md-12 bg-light rounded p-4">
                                        <div className="w-100 border bg-white p-3 rounded mb-3">
                                            <div className="row">
                                                <div className="col-md-3  border-end p-3">
                                                    <h3 className="box-sub-title">₹ 0</h3>
                                                    <p className="text-black">Withdrawable Balance</p>
                                                </div>
                                                <div className="col-md-9 ps-4">
                                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                        <div className="position-relative">
                                                            <label className="form-label m-0 position-absolute top-0 start-0 h-100 d-flex align-items-center text-secondary">
                                                                ₹
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="border-0 border-bottom w-100 p-2 text-secondary ps-3"
                                                                name=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <a href="#" style={{ fontSize: 12 }}>
                                                                History
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <p
                                                            className="text-black text-secondary mt-4"
                                                            style={{ fontSize: 12 }}
                                                        >
                                                            Govt policy: 30% Tax will apply on 'Net Winnings’.{" "}
                                                            <a href="#" style={{ fontSize: 12 }}>
                                                                Know more
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 border bg-white p-3 rounded">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link active"
                                                        id="upiTab-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#upiTab"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="upiTab"
                                                        aria-selected="true"
                                                    >
                                                        UPI
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link"
                                                        id="bankacTab-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#bankacTab"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="bankacTab"
                                                        aria-selected="false"
                                                    >
                                                        Bank Account
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content pt-3" id="myTabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="upiTab"
                                                    role="tabpanel"
                                                    aria-labelledby="upiTab-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <input
                                                                type="text"
                                                                className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                placeholder="Enter your UPI ID*"
                                                                name=""
                                                            />
                                                            <span className="upiformat">
                                                                Format - username@bankname
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="bankacTab"
                                                    role="tabpanel"
                                                    aria-labelledby="bankacTab-tab"
                                                >
                                                    <div className="box-block border pt-0 ps-0 pe-0">
                                                        <h2 className="banktab-block text-black bg-light p-3 border-bottom">
                                                            Bank Details
                                                        </h2>
                                                        <div className="row p-3">
                                                            <div className="col-md-6">
                                                                <div className="w-100 mb-3">
                                                                    <input
                                                                        type="number"
                                                                        className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                        placeholder="Enter Account Number*"
                                                                        name=""
                                                                    />
                                                                </div>
                                                                <div className="w-100 mb-0">
                                                                    <input
                                                                        type="number"
                                                                        className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                        placeholder="Re-Enter Account Number*"
                                                                        name=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <ul
                                                                    className="nav nav-tabs"
                                                                    id="myTab"
                                                                    role="tablist"
                                                                >
                                                                    <li className="nav-item" role="presentation">
                                                                        <button
                                                                            className="nav-link active"
                                                                            id="searchIfscTab-tab"
                                                                            data-bs-toggle="tab"
                                                                            data-bs-target="#searchIfscTab"
                                                                            type="button"
                                                                            role="tab"
                                                                            aria-controls="searchIfscTab"
                                                                            aria-selected="false"
                                                                        >
                                                                            Search by IFSC
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button
                                                                            className="nav-link"
                                                                            id="searchBankTab-tab"
                                                                            data-bs-toggle="tab"
                                                                            data-bs-target="#searchBankTab"
                                                                            type="button"
                                                                            role="tab"
                                                                            aria-controls="searchBankTab"
                                                                            aria-selected="false"
                                                                        >
                                                                            Search by Bank Name
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                                <div className="tab-content pt-3" id="myTabContent2">
                                                                    <div
                                                                        className="tab-pane fade show active"
                                                                        id="searchIfscTab"
                                                                        role="tabpanel"
                                                                        aria-labelledby="searchIfscTab-tab"
                                                                    >
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-8">
                                                                                <input
                                                                                    type="text"
                                                                                    className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                                    placeholder="IFSC Code*"
                                                                                    name=""
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <button className="allBtn w-100 bankSearchBtn">
                                                                                    Search
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="tab-pane fade"
                                                                        id="searchBankTab"
                                                                        role="tabpanel"
                                                                        aria-labelledby="searchBankTab-tab"
                                                                    >
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                                            placeholder="Bank Name"
                                                                                            name=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="border-0 border-bottom p-2 text-secondary ps-0 w-100 inputTab"
                                                                                            placeholder="Branch Name"
                                                                                            name=""
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <button className="allBtn w-100 bankSearchBtn">
                                                                                    Search
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Footer />
        </>
    )
}
