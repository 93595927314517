import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QRCode from "react-qr-code";
import { useSelector } from 'react-redux';
import '../transaction-qr.css'
import { useKvmStatusMutation } from '../redux/Services/apis';
import { toast } from 'react-toastify';

export default function KVMTransaction() {

    const navigate = useNavigate();

    const [kvmStatus, { data: statusData, isSuccess }] = useKvmStatusMutation()

    const data = useSelector(state => state.apis?.mutations?.kvm?.data?.data)

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [time, setTime] = useState('')
    const [timer, setTimer] = useState(null)
    let countdownInterval;

    const checkStatus = () => {
        setButtonDisabled(true);
        const transactionId = data?.transaction_id;
        kvmStatus({ transactionId })
        startCountdown(8);
    }

    function startCountdown(minutes) {
        let seconds = minutes * 60;

        countdownInterval = setInterval(function () {
            const minutesLeft = Math.floor(seconds / 60);
            const secondsLeft = seconds % 60;
            console.log(seconds % 60);
            if (seconds <= 0) {
                setTime('')
                clearInterval(countdownInterval);
                console.log("Countdown finished!");
            } else {
                setTime(minutesLeft + "m " + secondsLeft + "s ")
                seconds--;
            }
            if (seconds % 60 === 0) {
                const transactionId = data?.transaction_id;
                kvmStatus({ transactionId })
            }
        }, 1000);
        setTimer(countdownInterval)
    }

    useEffect(() => {
        if (statusData && isSuccess) {
            if (statusData.data?.transaction_status === 'SUCCESS') {
                clearInterval(countdownInterval);
                toast.success("Your Deposit Successfully Completed!", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                navigate('/addCash')
            } else if (statusData.data?.transaction_status === 'PENDING') {
                console.log(statusData);
            }
        }
    }, [statusData, isSuccess])


    return (
        <div className="w-100 fullpage">
            {data ? <div div className="w-3" >
                <div className="back-top" onClick={() => { clearInterval(timer); navigate('/addCash') }}>
                    <i className="fa-solid fa-chevron-left" />
                </div>
                <div className="w-100 text-ceter d-flex">
                    <button className="addFunds">
                        <i className="fa-solid fa-wallet" /> Add Funds
                    </button>
                </div>
                <div className="w-100 px-3">
                    <div className="mb-3 w-100">
                        <label className="form-label">Method</label>
                        <div className="position-relative">
                            <select className="form-control ps-3">
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                                <option>Phonepe</option>
                            </select>
                            <img src="assets/img/phonepe.png" className="method-icon" />
                        </div>
                    </div>
                    <div className="mb-3 w-100">
                        <label className="form-label">Amount</label>
                        <div className="position-relative">
                            <input type="text" className="form-control ps-5" defaultValue={localStorage.getItem('amount')} disabled name="" />
                            <img src="assets/img/ruppe.png" className="method-icon" />
                        </div>
                    </div>
                    <label className="form-label">Instructions</label>
                    <div className="w-100 instructions-block">
                        <div className="step-text">
                            <strong>Step 1:</strong>
                            Take screenshot of QR code or copy the UPI ID
                        </div>
                        <div className="step-text">
                            <strong>Step 2:</strong>
                            Gp to UPI app and scan the QR code or paste the UPI ID
                        </div>
                        <div className="step-text">
                            <strong>Step 3:</strong>
                            After successful payment enter the transaction ID/UTR
                        </div>
                        <div className="step-text">
                            <strong>Step 4:</strong>
                            Click on submit: payment will be added instantly or in 3-5 mins.
                        </div>
                        <div className="d-flex" style={{ marginTop: 30 }}>
                            <div className="qr-code">
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={data.QR_code}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <div className="text-ceter">
                            <div id="countdown" className='text-dark mt-4'>{time}</div>
                            <button className="submit-btn" onClick={checkStatus} disabled={buttonDisabled}>Submit</button>
                        </div>
                    </div>
                    <div className="text-ceter">
                        <button className="submit-btn support-btn">Support</button>
                    </div>
                </div>
            </div > : ''}
        </div >

    )
}
